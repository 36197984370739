(function (factory) {
  typeof define === 'function' && define.amd ? define('oloPay', factory) :
  factory();
})((function () { 'use strict';

  var Feature;
  (function (Feature2) {
    Feature2["CreditCardElements"] = "CreditCardElements";
    Feature2["CvvCardElement"] = "CvvCardElement";
    Feature2["DigitalWallets"] = "DigitalWallets";
    Feature2["SingleLineCardElement"] = "SingleLineCardElement";
  })(Feature || (Feature = {}));
  const log = (...data) => console.log("%c Olo Pay ", "background: #25B3E2; color: #ffffff", ...data);
  const warn = (...data) => console.warn("%c Olo Pay ", "background: #F3A966; color: #ffffff", ...data);
  const throwError = message2 => {
    if (message2) {
      throw new Error(`Olo Pay Error: ${message2}`);
    }
  };
  const error = (...data) => {
    console.error("%c Olo Pay Error ", "background: #FF2A53; color: #ffffff", ...data);
  };
  const message = {
    log,
    warn,
    error,
    throw: throwError
  };
  var pure$1 = {};
  Object.defineProperty(pure$1, "__esModule", {
    value: true
  });
  function _typeof(obj) {
    "@babel/helpers - typeof";

    if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
      _typeof = function (obj2) {
        return typeof obj2;
      };
    } else {
      _typeof = function (obj2) {
        return obj2 && typeof Symbol === "function" && obj2.constructor === Symbol && obj2 !== Symbol.prototype ? "symbol" : typeof obj2;
      };
    }
    return _typeof(obj);
  }
  var V3_URL$1 = "https://js.stripe.com/v3";
  var V3_URL_REGEX$1 = /^https:\/\/js\.stripe\.com\/v3\/?(\?.*)?$/;
  var EXISTING_SCRIPT_MESSAGE$1 = "loadStripe.setLoadParameters was called but an existing Stripe.js script already exists in the document; existing script parameters will be used";
  var findScript$1 = function findScript() {
    var scripts = document.querySelectorAll('script[src^="'.concat(V3_URL$1, '"]'));
    for (var i = 0; i < scripts.length; i++) {
      var script = scripts[i];
      if (!V3_URL_REGEX$1.test(script.src)) {
        continue;
      }
      return script;
    }
    return null;
  };
  var injectScript$1 = function injectScript(params) {
    var queryString = params && !params.advancedFraudSignals ? "?advancedFraudSignals=false" : "";
    var script = document.createElement("script");
    script.src = "".concat(V3_URL$1).concat(queryString);
    var headOrBody = document.head || document.body;
    if (!headOrBody) {
      throw new Error("Expected document.body not to be null. Stripe.js requires a <body> element.");
    }
    headOrBody.appendChild(script);
    return script;
  };
  var registerWrapper = function registerWrapper2(stripe, startTime) {
    if (!stripe || !stripe._registerWrapper) {
      return;
    }
    stripe._registerWrapper({
      name: "stripe-js",
      version: "1.54.0",
      startTime
    });
  };
  var stripePromise$2 = null;
  var loadScript$1 = function loadScript(params) {
    if (stripePromise$2 !== null) {
      return stripePromise$2;
    }
    stripePromise$2 = new Promise(function (resolve, reject) {
      if (typeof window === "undefined" || typeof document === "undefined") {
        resolve(null);
        return;
      }
      if (window.Stripe && params) {
        console.warn(EXISTING_SCRIPT_MESSAGE$1);
      }
      if (window.Stripe) {
        resolve(window.Stripe);
        return;
      }
      try {
        var script = findScript$1();
        if (script && params) {
          console.warn(EXISTING_SCRIPT_MESSAGE$1);
        } else if (!script) {
          script = injectScript$1(params);
        }
        script.addEventListener("load", function () {
          if (window.Stripe) {
            resolve(window.Stripe);
          } else {
            reject(new Error("Stripe.js not available"));
          }
        });
        script.addEventListener("error", function () {
          reject(new Error("Failed to load Stripe.js"));
        });
      } catch (error2) {
        reject(error2);
        return;
      }
    });
    return stripePromise$2;
  };
  var initStripe = function initStripe2(maybeStripe, args, startTime) {
    if (maybeStripe === null) {
      return null;
    }
    var stripe = maybeStripe.apply(undefined, args);
    registerWrapper(stripe, startTime);
    return stripe;
  };
  var validateLoadParams = function validateLoadParams2(params) {
    var errorMessage = "invalid load parameters; expected object of shape\n\n    {advancedFraudSignals: boolean}\n\nbut received\n\n    ".concat(JSON.stringify(params), "\n");
    if (params === null || _typeof(params) !== "object") {
      throw new Error(errorMessage);
    }
    if (Object.keys(params).length === 1 && typeof params.advancedFraudSignals === "boolean") {
      return params;
    }
    throw new Error(errorMessage);
  };
  var loadParams;
  var loadStripeCalled = false;
  var loadStripe = function loadStripe2() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    loadStripeCalled = true;
    var startTime = Date.now();
    return loadScript$1(loadParams).then(function (maybeStripe) {
      return initStripe(maybeStripe, args, startTime);
    });
  };
  loadStripe.setLoadParameters = function (params) {
    if (loadStripeCalled && loadParams) {
      var validatedParams = validateLoadParams(params);
      var parameterKeys = Object.keys(validatedParams);
      var sameParameters = parameterKeys.reduce(function (previousValue, currentValue) {
        var _loadParams;
        return previousValue && params[currentValue] === ((_loadParams = loadParams) === null || _loadParams === undefined ? undefined : _loadParams[currentValue]);
      }, true);
      if (sameParameters) {
        return;
      }
    }
    if (loadStripeCalled) {
      throw new Error("You cannot change load parameters after calling loadStripe");
    }
    loadParams = validateLoadParams(params);
  };
  pure$1.loadStripe = loadStripe;
  var pure = pure$1;
  const loadWithoutSideEffect = pure.loadStripe;
  var URL$1;
  (function (URL2) {
    URL2["development"] = "https://static.olocdn.net/web-client/olo-pay/keys/dev.json";
    URL2["production"] = "https://static.olocdn.net/web-client/olo-pay/keys/prod.json";
  })(URL$1 || (URL$1 = {}));
  async function setupStripe(feature, key) {
    let stripe = null;
    let imported = false;
    try {
      if (stripe === null) {
        imported = true;
        stripe = await loadWithoutSideEffect(key);
      }
      if (stripe === null) {
        message.error(`Failed to load Stripe. Tried import = ${imported}`);
        throw new Error();
      }
      return stripe;
    } catch (e) {
      if (e instanceof Error) {
        message.error(`${feature}: ${e.message}`);
        message.error(`${feature}: ${e.stack}`);
        console.error("%c Olo Pay Error ", "background: #FF2A53; color: #ffffff", `${feature}: ${e.message}`);
        console.error("%c Olo Pay Error Stack Trace ", "background: #FFA500; color: #ffffff", `${feature}: ${e.stack}`);
      }
    }
  }
  async function fetchKey(url, env) {
    message.log(`Fetching key for ${env}`);
    try {
      const response = await fetch(url);
      const json = await response.json();
      return json.key.trim() || "";
    } catch (e) {
      if (e instanceof Error) {
        message.error(`Failed to fetch key ${JSON.stringify(e)}`);
      }
      return "";
    }
  }
  async function configureStripe(feature, env) {
    message.log(`Setting up Stripe for ${env}`);
    const url = /\bproduction\b/i.test(env) ? URL$1.production : URL$1.development;
    const key = await fetchKey(url, env);
    const stripe = await setupStripe(feature, key).catch(err => message.error(err));
    if (!stripe) {
      message.error(`${feature}: Failed to access Olo Pay`);
      return undefined;
    }
    return stripe;
  }
  const defaultBase = {
    color: "inherit",
    backgroundColor: "inherit",
    fontFamily: "inherit",
    fontSize: "1.8rem",
    fontSmoothing: "inherit",
    fontStyle: "inherit",
    fontVariant: "inherit",
    fontWeight: "inherit",
    iconColor: "#25B3E2",
    lineHeight: "inherit",
    letterSpacing: "inherit",
    textAlign: "inherit",
    textDecoration: "inherit",
    textShadow: "inherit",
    textTransform: "inherit"
  };
  const defaultPseudos = {
    ":hover": defaultBase,
    ":focus": defaultBase,
    "::placeholder": defaultBase,
    "::selection": defaultBase,
    ":-webkit-autofill": defaultBase,
    ":disabled": defaultBase,
    "::-ms-clear": {
      ...defaultBase,
      display: "string"
    }
  };
  const defaultStyles = {
    ...defaultBase,
    ...defaultPseudos
  };
  const defaultStyleObject = {
    base: {
      ...defaultStyles
    },
    complete: {
      ...defaultStyles
    },
    empty: {
      ...defaultStyles
    },
    invalid: {
      ...defaultStyles
    }
  };
  const isElement = item => item.nodeType === 1 && item.matches("*");
  function find(target) {
    if (isElement(target)) {
      const element = target;
      return element.isConnected ? element : null;
    }
    try {
      return document.querySelector(target);
    } catch (error2) {
      message.error(`No element marked with ${target} found`);
      return null;
    }
  }
  const TEST_PAYMENT_METHOD = {
    paymentMethod: {
      id: "pm_visa_test_mode",
      object: "payment_method",
      billing_details: {
        address: {
          city: "New York",
          country: "US",
          line1: "One World Trade Center",
          line2: null,
          postal_code: "10006",
          state: "NY"
        },
        email: "olopaytest@test.com",
        name: "Test Mode",
        phone: "4444444444"
      },
      card: {
        brand: "visa",
        checks: {
          address_line1_check: null,
          address_postal_code_check: null,
          cvc_check: null
        },
        country: "US",
        exp_month: 12,
        exp_year: 2031,
        funding: "credit",
        generated_from: null,
        last4: "1234",
        networks: {
          available: ["visa"],
          preferred: null
        },
        three_d_secure_usage: {
          supported: true
        },
        wallet: {
          dynamic_last4: "1234",
          google_pay: {},
          type: "google_pay"
        }
      },
      created: 1628283848,
      customer: null,
      livemode: false,
      type: "card"
    }
  };
  class DigitalWallets {
    // ===========================================================================
    // Constructor
    // ===========================================================================
    constructor(environment = "development") {
      Object.defineProperty(this, "environment", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: environment
      });
      Object.defineProperty(this, "stripe", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: undefined
      });
      Object.defineProperty(this, "digitalWalletsOptions", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: undefined
      });
      Object.defineProperty(this, "paymentEvent", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: undefined
      });
      Object.defineProperty(this, "paymentRequest", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: undefined
      });
      Object.defineProperty(this, "defaultPaymentRequestOptions", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: {
          currency: "usd",
          total: {
            label: "test",
            amount: 0
          },
          requestPayerName: true
        }
      });
      Object.defineProperty(this, "canMakePayment", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: null
      });
      Object.defineProperty(this, "paymentButton", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: undefined
      });
    }
    // ===========================================================================
    // Methods
    // ===========================================================================
    /**
     * Initializes a digital wallet's payment request so that a `DigitalWallets` button can be created.
     *
     * @param options Digital wallets options containing style and payment request options.
     * @example
     * HTML
     * <div data-olo-pay-payment-button></div>
     *
     * @example
     * JS
     * const digitalWallets = new DigitalWallets();
     * await digitalWallets.initialize(options);
     *
     * // Will return an object of { applePay: false, googlePay: false }
     * const canMakePayment = digitalWallets.canMakePayment;
     */
    async initialize(options) {
      if (!this.stripe) {
        this.stripe = await configureStripe(Feature.DigitalWallets, this.environment);
      }
      if (!this.stripe) return;
      if (!options) {
        message.error("No digital wallets options provided");
        return;
      }
      this.digitalWalletsOptions = options;
      if (this.paymentRequest && !this.paymentRequest.isShowing()) {
        const {
          currency,
          total,
          displayItems,
          shippingOptions
        } = this.digitalWalletsOptions.options;
        this.updatePaymentDetails({
          currency,
          total,
          displayItems,
          shippingOptions
        });
        this.canMakePayment = await this.paymentRequest.canMakePayment();
        return;
      }
      const paymentRequest = this.stripe.paymentRequest(this.digitalWalletsOptions.options);
      this.canMakePayment = await paymentRequest.canMakePayment();
      this.paymentRequest = paymentRequest;
    }
    /**
     * Appends a `Stripe Elements` Payment Request Button to an element marked with a matching `data-olo-pay-payment-button` attribute.
     * @param callback Function to execute after the payment is submitted in the digital wallets payment sheet.
     *
     * @example
     * HTML
     * <div data-olo-pay-payment-button></div>
     *
     * @example
     * JS
     * const digitalWallets = new DigitalWallets();
     * await digitalWallets.initialize(options);
     * digitalWallets.mountButton(callback);
     */
    mountButton(callback, target) {
      if (!this.digitalWalletsOptions || !this.paymentRequest || !this.stripe) {
        message.error("Digital Wallets not initialized properly.");
        return;
      }
      const paymentRequest = this.paymentRequest;
      paymentRequest.on("paymentmethod", ev => {
        this.paymentEvent = ev;
        callback(ev.paymentMethod);
      });
      const buttonSlot = find(target || "[data-olo-pay-payment-button]");
      if (!buttonSlot) return;
      const {
        classes,
        style
      } = this.digitalWalletsOptions;
      const elements = this.stripe.elements();
      const button = elements.create("paymentRequestButton", {
        paymentRequest,
        // Add optional classes object if it exists
        ...(classes ? classes : {}),
        // Add optional style object if it exists
        ...(style ? {
          style: {
            paymentRequestButton: {
              ...style
            }
          }
        } : {})
      });
      if (buttonSlot && this.canMakePayment) {
        button.mount("[data-olo-pay-payment-button]");
      } else {
        buttonSlot.style.display = "none";
      }
      if (this.environment === "test") {
        message.log("Digital Wallets is running in test mode.");
        button.on("click", event => {
          event.preventDefault();
          window.PaymentRequest = paymentRequest;
          window.PaymentRequest.emit("paymentmethod", TEST_PAYMENT_METHOD);
        });
      }
      this.paymentButton = button;
    }
    /**
     * Initiates a payment by showing the Google Pay or Apple Pay payment sheet.
     * This can be triggered when using a custom payment button,
     * or in scenarios where preventing the default 'click' event behavior is necessary
     * (i.e. proper form validation prior to initiating the payment).
     *
     * @example
     * const digitalWallets = new DigitalWallets();
     * await digitalWallets.initialize(options);
     *
     * digitalWallets.paymentButton.on('click', (event) => {
     *  event.preventDefault();
     *
     *  // Launch payment sheet if form is valid
     *  if (formIsValid) {
     *    dw.initiatePayment();
     *  }
     * });
     */
    initiatePayment() {
      var _a;
      if (this.canMakePayment) {
        (_a = this.paymentRequest) == null ? undefined : _a.show();
      }
    }
    /**
     * Determines which digital wallets are available in the current browser context.
     *
     * @example
     * const canRenderButton = await digitalWallets.canRenderButton();
     * if (canRenderButton && (canRenderButton.applePay || canRenderButton.googlePay)) {
     *  // Do something
     * }
     */
    async canRenderButton(country = "US") {
      var _a;
      this.stripe = await configureStripe(Feature.DigitalWallets, this.environment);
      if (!this.stripe) return null;
      this.paymentRequest = (_a = this.stripe) == null ? undefined : _a.paymentRequest({
        ...this.defaultPaymentRequestOptions,
        country
      });
      this.canMakePayment = await this.paymentRequest.canMakePayment();
      return this.canMakePayment;
    }
    /**
     * Updates payment details to be reflected on the payment sheet
     */
    updatePaymentDetails(options) {
      if (!this.paymentRequest) return;
      this.paymentRequest.update(options);
    }
    /**
     * Completes the pending payment event to close the active payment sheet
     */
    completePaymentEvent() {
      var _a;
      (_a = this.paymentEvent) == null ? undefined : _a.complete("success");
    }
    /**
     * Fails the pending payment event
     */
    failPaymentEvent() {
      var _a;
      (_a = this.paymentEvent) == null ? undefined : _a.complete("fail");
    }
    /**
     * Removes the element from the DOM and destroys it.
     * A destroyed element can not be re-activated or re-mounted to the DOM.
     */
    destroy() {
      var _a;
      (_a = this.paymentButton) == null ? undefined : _a.destroy();
    }
    /**
     * Unmounts the element from the DOM. Call element.mount to re-attach it to the DOM.
     */
    unmount() {
      var _a;
      (_a = this.paymentButton) == null ? undefined : _a.unmount();
    }
  }
  class SingleLineCardElement {
    // ===========================================================================
    // Constructor
    // ===========================================================================
    constructor(environment = "development") {
      Object.defineProperty(this, "environment", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: environment
      });
      Object.defineProperty(this, "stripe", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: undefined
      });
      Object.defineProperty(this, "defaultMountTarget", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: "[data-olo-pay-card-single-line]"
      });
      Object.defineProperty(this, "defaultOptions", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: {
          classes: {
            base: "olo-pay",
            complete: "olo-pay--complete",
            empty: "olo-pay--empty",
            focus: "olo-pay--focus",
            invalid: "olo-pay--invalid",
            webkitAutofill: "olo-pay--webkit-autofill"
          }
        }
      });
      Object.defineProperty(this, "element", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: undefined
      });
    }
    // ===========================================================================
    // Methods
    // ===========================================================================
    /**
     * Appends a single-line credit card field to a passed element or selector of a DOM element.
     *
     * If no value for `mountTarget` is passed in, the default mount target is `data-olo-pay-card-single-line`.
     *
     * @param cardOptions Options object containing information for:
     * `cardElementOptions`, `elementsOptions`, `mountTarget`.
     */
    async create(cardOptions) {
      this.stripe = await configureStripe(Feature.SingleLineCardElement, this.environment);
      if (!this.stripe) return;
      const elements = this.stripe.elements((cardOptions == null ? undefined : cardOptions.elementsOptions) || {});
      const options = {
        ...this.defaultOptions,
        ...(cardOptions == null ? undefined : cardOptions.cardElementOptions)
      };
      const card = elements.create("card", {
        ...options
      });
      const target = (cardOptions == null ? undefined : cardOptions.mountTarget) || this.defaultMountTarget;
      try {
        card.mount(target);
      } catch (e) {
        message.error(`${Feature.SingleLineCardElement}:
          Unable to mount Olo Pay single-line card element to its DOM target
          Please ensure valid selectors or elements are passed to \`create\``);
        return;
      }
      this.element = elements.getElement("card") || undefined;
      if (!this.element) return message.error("Single-line card element not mounted properly");
      return;
    }
    /**
     * Creates a payment method using card details on the form and
     * billing details (if passed).
     *
     * @param billingDetails Address, email, name, and phone.
     */
    async createPaymentMethod(billingDetails) {
      if (!this.stripe || !this.element) {
        message.error(`Failed to create a payment method due to missing: ${this.stripe ? "singleLineCard element" : "Olo Pay connection. Call `create` first"}`);
        return;
      }
      const paymentMethod = await this.stripe.createPaymentMethod({
        type: "card",
        card: this.element,
        billing_details: billingDetails
      });
      return paymentMethod;
    }
    /**
     * Applies a style object to the element.
     *
     * @param style Style object.
     */
    applyStyles(style = defaultStyleObject) {
      var _a;
      (_a = this.element) == null ? undefined : _a.update({
        style
      });
    }
    /**
     * Clear all credit card field values.
     */
    clear() {
      var _a;
      (_a = this.element) == null ? undefined : _a.clear();
    }
    /**
     * Removes the element from the DOM and destroys it.
     * A destroyed element can not be re-activated or re-mounted to the DOM.
     */
    destroy() {
      var _a;
      (_a = this.element) == null ? undefined : _a.destroy();
      this.element = undefined;
    }
    /**
     * Mounts the element to the DOM.
     * Call `unmount` to remove it from the DOM.
     *
     * @param mountTarget Object containing selector or element target.
     */
    mount(mountTarget = this.defaultMountTarget) {
      var _a;
      (_a = this.element) == null ? undefined : _a.mount(mountTarget);
    }
    /**
     * Unmounts the element from the DOM.
     * Call `mount` to re-attach it to the DOM.
     */
    unmount() {
      var _a;
      (_a = this.element) == null ? undefined : _a.unmount();
    }
    /**
     * Updates the options that the element was initialized with.
     * Updates are merged into the existing configuration.
     *
     * @param newOptions
     */
    update(newOptions = this.defaultOptions) {
      var _a;
      (_a = this.element) == null ? undefined : _a.update(newOptions);
    }
  }
  var V3_URL = "https://js.stripe.com/v3";
  var V3_URL_REGEX = /^https:\/\/js\.stripe\.com\/v3\/?(\?.*)?$/;
  var EXISTING_SCRIPT_MESSAGE = "loadStripe.setLoadParameters was called but an existing Stripe.js script already exists in the document; existing script parameters will be used";
  var findScript2 = function findScript3() {
    var scripts = document.querySelectorAll('script[src^="'.concat(V3_URL, '"]'));
    for (var i = 0; i < scripts.length; i++) {
      var script = scripts[i];
      if (!V3_URL_REGEX.test(script.src)) {
        continue;
      }
      return script;
    }
    return null;
  };
  var injectScript2 = function injectScript3(params) {
    var queryString = "";
    var script = document.createElement("script");
    script.src = "".concat(V3_URL).concat(queryString);
    var headOrBody = document.head || document.body;
    if (!headOrBody) {
      throw new Error("Expected document.body not to be null. Stripe.js requires a <body> element.");
    }
    headOrBody.appendChild(script);
    return script;
  };
  var stripePromise = null;
  var loadScript2 = function loadScript3(params) {
    if (stripePromise !== null) {
      return stripePromise;
    }
    stripePromise = new Promise(function (resolve, reject) {
      if (typeof window === "undefined" || typeof document === "undefined") {
        resolve(null);
        return;
      }
      if (window.Stripe) {
        resolve(window.Stripe);
        return;
      }
      try {
        var script = findScript2();
        if (script && params) ; else if (!script) {
          script = injectScript2(params);
        }
        script.addEventListener("load", function () {
          if (window.Stripe) {
            resolve(window.Stripe);
          } else {
            reject(new Error("Stripe.js not available"));
          }
        });
        script.addEventListener("error", function () {
          reject(new Error("Failed to load Stripe.js"));
        });
      } catch (error2) {
        reject(error2);
        return;
      }
    });
    return stripePromise;
  };
  var stripePromise$1 = Promise.resolve().then(function () {
    return loadScript2(null);
  });
  stripePromise$1["catch"](function (err) {
    {
      console.warn(err);
    }
  });

  jQuery(function ($) {
    const $orderPage = $('.page-template-page-catering-order');
    const $thirdStep = $('.third-step');

    // If the page or third step element is not found, exit early
    if ($orderPage.length == 0 || $thirdStep.length == 0) return;
    const searchParams = new URL(window.location.href).searchParams;
    const restaurantID = searchParams.get('restaurant_id');
    const basketGuid = searchParams.get('basket_guid');
    const validateBasketOptions = {
      basket_guid: basketGuid
    };
    const orderOptions = {
      basket_guid: basketGuid,
      billing_scheme_id: '',
      // only for digitalwallet
      billing_method: '',
      user_type: 'guest',
      first_name: '',
      last_name: '',
      email_address: '',
      contact_number: '',
      token: '',
      card_type: '',
      card_last_four: '',
      expiry_year: '',
      zip: '',
      save_on_file: 'false' // only for creditcardtoken
    };

    /**
     * Single Line Credit Card
     */

    let environment = 'production'; // Default value

    // Getting environment via Ajax request
    const getEnvironment = async () => {
      try {
        const response = await $.ajax({
          url: tacoVars.ajaxurl,
          method: 'POST',
          data: {
            action: 'olo_get_environment',
            nonce: tacoVars.ajaxNonce
          },
          success: function (response) {
            return response;
          }
        });
        if (response.success && response.data.environment) {
          console.log(response.data.environment);
          environment = response.data.environment == 'sandbox' ? 'development' : 'production';

          // Initializing components with the received environment
          initializePaymentComponents(environment);
        } else {
          console.log(`Failed to get environment, using default ${environment}`);
          initializePaymentComponents(environment);
        }
      } catch (error) {
        console.log(`Error getting environment, using default ${environment}`, error);
        initializePaymentComponents(environment);
      }
    };
    const initializePaymentComponents = env => {
      const cardElement = new SingleLineCardElement(env);
      const cardOptions = {
        cardElementOptions: {
          style: {
            base: {
              fontSize: '20px'
            }
          }
        }
      };
      cardElement.create(cardOptions);

      /**
       * Digital Wallets
       */

      const digitalWalletsCallback = async paymentMethod => {
        closeInfoModal();
        if (paymentMethod) {
          let basketValidateResponse;
          try {
            basketValidateResponse = await validateBasket(validateBasketOptions);
          } catch (error) {
            console.log(error);
            digitalWallets.failPaymentEvent();
            return false;
          }
          if (basketValidateResponse && basketValidateResponse?.success == false) {
            console.log({
              basketValidateResponse
            });
            openInfoModal('pay-error');
            $oloFormBtn.removeClass('is-loading');
            digitalWallets.failPaymentEvent();
            return false;
          }

          // Use your basket submission API call here to send the payment method id as `token`
          const formData = new FormData($oloForm[0]);
          orderOptions.billing_method = 'digitalwallet';
          orderOptions.billing_scheme_id = 'digitalwallet';
          orderOptions.first_name = formData.get('first_name');
          orderOptions.last_name = formData.get('last_name');
          orderOptions.email_address = formData.get('email_address');
          orderOptions.contact_number = formData.get('contact_number');
          orderOptions.token = paymentMethod.id;
          orderOptions.card_type = paymentMethod.card.brand;
          orderOptions.card_last_four = paymentMethod.card.last4;
          orderOptions.expiry_year = paymentMethod.card.exp_year;
          orderOptions.expiry_month = paymentMethod.card.exp_month;
          orderOptions.zip = paymentMethod.billing_details.address.postal_code;
          const walletType = paymentMethod?.card?.wallet?.type;
          let billingSchemesIds = Cookies.get(`wordpress_catering_${restaurantID}_billing_schemes_ids`);
          if (!billingSchemesIds) {
            return false;
          }
          billingSchemesIds = JSON.parse(billingSchemesIds);
          const billingScheme = billingSchemesIds.filter(bSchemeId => bSchemeId.name == walletType);
          if (!billingScheme[0]?.id) {
            console.log(billingScheme);
            return false;
          }
          orderOptions.billing_scheme_id = billingScheme[0].id;
          let submitOrderResponse;
          try {
            submitOrderResponse = await submitOrder(orderOptions);
          } catch (error) {
            openInfoModal('pay-error');
            $oloFormBtn.removeClass('is-loading');

            // Signals to the Apple Pay or Google Pay payment sheet that the payment has failed
            digitalWallets.failPaymentEvent();
            return false;
          }
          console.log({
            submitOrderResponse
          });
          if (submitOrderResponse && submitOrderResponse?.success) {
            // Signals to the Apple Pay or Google Pay payment sheet that the transaction has been processed
            // successfully and that the payment sheet can be closed
            digitalWallets.completePaymentEvent();
            const orderId = submitOrderResponse.data.id;
            saveOrderCookies(orderId, restaurantID);

            // Clear payment-related cookies and localStorage after successful order
            clearCateringCookies({
              restaurantID: restaurantID,
              isBasketDataDelete: true
            });
            clearCateringLocalStorage({
              restaurantID: restaurantID,
              isBasketDataDelete: true
            });

            // Redirect to Success Page
            window.location.href = createOrderUrl(submitOrderResponse);
          } else {
            if (submitOrderResponse?.data?.response?.code == 200) {
              openInfoModal('pay-error');
            }
            $oloFormBtn.removeClass('is-loading');

            // Signals to the Apple Pay or Google Pay payment sheet that the payment has failed
            digitalWallets.failPaymentEvent();
            return false;
          }
        }
      };
      const basket = ExpiringLocalStorage.get(`wordpress_catering_${restaurantID}_basket`);

      // If basket is not found, clear cookies and local storage and redirect to home page
      if (!basket) {
        clearCateringCookies({
          restaurantID: restaurantID,
          isBasketDataDelete: true
        });
        clearCateringLocalStorage({
          restaurantID: restaurantID,
          isBasketDataDelete: true
        });
        window.location.href = window.location.origin;
      }
      const digitalWalletsOptions = {
        options: {
          country: 'US',
          currency: 'usd',
          total: {
            label: 'Pay Taco Time NW Catering',
            amount: Math.round(basket.total * 100)
          },
          displayItems: [{
            label: 'Subtotal',
            amount: Math.round(basket.subtotal * 100)
          }],
          requestPayerName: true
        },
        style: {
          type: 'default',
          theme: 'dark',
          height: '40px',
          window: '150px',
          buttonRadius: 8
        }
      };

      // Add taxes to display items
      if (basket.taxes.length !== 0) {
        for (let index = 0; index < basket.taxes.length; index++) {
          digitalWalletsOptions.options.displayItems.push({
            label: basket.taxes[index].label,
            // label: 'Sales Tax',
            amount: Math.round(basket.taxes[index].tax * 100)
          });
        }
      }

      // Add discount to display items
      if (basket.discount && basket.discount > 0) {
        const discount = Math.round(basket.discount * 100 * -1);
        digitalWalletsOptions.options.displayItems.push({
          label: 'Discount',
          amount: discount
        });
      }
      const digitalWallets = new DigitalWallets(env);
      const walletsInit = async () => {
        try {
          await digitalWallets.initialize(digitalWalletsOptions);
          digitalWallets.mountButton(digitalWalletsCallback);
          if (digitalWallets?.canMakePayment?.googlePay && digitalWallets?.canMakePayment?.applePay) {
            // show credit card button
            $('.payment-buttons').addClass('is-visible');
            $('.digital-wallet-buttons').addClass('is-visible');
            $('.credit-card-button').addClass('third-width is-visible');
            // hide card field
            $('.credit-card').addClass('is-hidden');
          } else if (digitalWallets?.canMakePayment?.googlePay || digitalWallets?.canMakePayment?.applePay) {
            $('.payment-buttons').addClass('is-visible');
            $('.digital-wallet-buttons').addClass('is-visible');
            $('.credit-card-button').addClass('half-width is-visible');
            // hide card field
            $('.credit-card').addClass('is-hidden');
          }

          // remove loader
          $('.payment-loader').removeClass('is-visible');

          // show payments
          $('.payment-content').addClass('is-visible');
        } catch (error) {
          console.log(error);
          openInfoModal('pay-error');
        }
      };
      if (basket) {
        walletsInit();
      }

      /**
       * Credit Card Form
       */
      const $oloForm = $('.olo-form');
      const $oloFormBtn = $('.olo-form-button');
      const oloFormSubmitHandler = async e => {
        e.preventDefault();
        $oloFormBtn.addClass('is-loading').prop('disabled', true);
        let pmResponse, paymentMethod;
        try {
          pmResponse = await cardElement.createPaymentMethod();
        } catch (error) {
          console.log(error);
          openInfoModal('pay-error');
          $oloFormBtn.removeClass('is-loading').prop('disabled', false);
          return false;
        }
        if (pmResponse?.paymentMethod) {
          paymentMethod = pmResponse.paymentMethod;
        } else {
          $oloFormBtn.removeClass('is-loading').prop('disabled', false);
          openInfoModal('pay-error');
          return false;
        }
        let basketValidateResponse;
        try {
          basketValidateResponse = await validateBasket(validateBasketOptions);
        } catch (error) {
          console.log(error);
          openInfoModal('pay-error');
          $oloFormBtn.removeClass('is-loading').prop('disabled', false);
          return false;
        }
        if (basketValidateResponse && basketValidateResponse?.success == false) {
          console.log({
            basketValidateResponse
          });
          $oloFormBtn.removeClass('is-loading').prop('disabled', false);
          openInfoModal('pay-error');
          $oloFormBtn.removeClass('is-loading').prop('disabled', false);
          return false;
        }
        const formData = new FormData(e.target);
        orderOptions.billing_method = 'creditcardtoken';
        orderOptions.first_name = formData.get('first_name');
        orderOptions.last_name = formData.get('last_name');
        orderOptions.email_address = formData.get('email_address');
        orderOptions.contact_number = formData.get('contact_number');
        orderOptions.token = paymentMethod.id;
        orderOptions.card_type = paymentMethod.card.brand;
        orderOptions.card_last_four = paymentMethod.card.last4;
        orderOptions.expiry_year = paymentMethod.card.exp_year;
        orderOptions.expiry_month = paymentMethod.card.exp_month;
        orderOptions.zip = paymentMethod.billing_details.address.postal_code;
        orderOptions.save_on_file = 'false'; // only for creditcardtoke

        let submitOrderResponse;
        try {
          submitOrderResponse = await submitOrder(orderOptions);
        } catch (error) {
          console.log(error);
          openInfoModal('pay-error');
          $oloFormBtn.removeClass('is-loading').prop('disabled', false);
          return false;
        }
        console.log({
          submitOrderResponse
        });
        if (submitOrderResponse && submitOrderResponse?.success) {
          const orderId = submitOrderResponse.data.id;
          saveOrderCookies(orderId, restaurantID);

          // Clear payment-related cookies and localStorage after successful order
          clearCateringCookies({
            restaurantID: restaurantID,
            isBasketDataDelete: true
          });
          clearCateringLocalStorage({
            restaurantID: restaurantID,
            isBasketDataDelete: true
          });

          // Redirect to Success Page
          window.location.href = createOrderUrl(submitOrderResponse);
        } else {
          openInfoModal('pay-error');
          $oloFormBtn.removeClass('is-loading').prop('disabled', false);
          return false;
        }
        $oloFormBtn.removeClass('is-loading').prop('disabled', false);
      };
      $oloForm.on('submit', oloFormSubmitHandler);

      /**
       * Coupon Management
       */

      const updateBasketAndPaymentMethods = async newBasket => {
        try {
          // Update basket in local storage
          ExpiringLocalStorage.set(`wordpress_catering_${restaurantID}_basket`, newBasket);

          // Update digital wallets options
          const updatedPaymentDetails = {
            currency: 'usd',
            total: {
              label: 'Pay Taco Time NW Catering',
              amount: Math.round(newBasket.total * 100)
            },
            displayItems: [{
              label: 'Subtotal',
              amount: Math.round(newBasket.subtotal * 100)
            }]
          };
          if (newBasket.taxes.length !== 0) {
            for (let index = 0; index < newBasket.taxes.length; index++) {
              updatedPaymentDetails.displayItems.push({
                label: newBasket.taxes[index].label,
                // label: 'Sales Tax',
                amount: Math.round(newBasket.taxes[index].tax * 100)
              });
            }
          }
          if (newBasket.discount && newBasket.discount > 0) {
            const discount = Math.round(newBasket.discount * 100 * -1);
            console.log({
              discount
            });
            updatedPaymentDetails.displayItems.push({
              label: 'Discount',
              amount: discount
            });
          }

          // Update digital wallets with new payment details
          digitalWallets.updatePaymentDetails(updatedPaymentDetails);
        } catch (error) {
          console.log(error);
          openInfoModal('pay-error');
        }
      };
      const $couponInput = $('#coupon');
      const couponInputChangeHandler = async e => {
        const $thisInput = $(e.target);
        const $parentField = $thisInput.parents('.ct-field');
        const $couponList = $('.coupon-list');
        const $subtotalCost = $('.subtotal-cost');
        const $couponDiscount = $('.coupon-discount');
        const $discountCost = $('.discount-cost');
        const $taxCost = $('.tax-cost');
        const $orderTotalCost = $('.order-total-cost');
        const coupon = $thisInput.val();
        $parentField.removeClass('is-invalid');
        if (coupon?.length == 0) {
          return false;
        }
        const ajaxData = {
          basket_guid: basketGuid,
          couponcode: coupon
        };
        let addCouponResponse;
        try {
          // Disable input and show loader
          $thisInput.prop('disabled', true);
          $parentField.addClass('is-loading');
          addCouponResponse = await applyCouponToBasket(ajaxData);
        } catch (error) {
          console.log(error);
          $parentField.addClass('is-invalid');
        } finally {
          // Enable input and hide loader
          $thisInput.prop('disabled', false).trigger('focus');
          $parentField.removeClass('is-loading');
        }
        if (addCouponResponse) {
          if (addCouponResponse?.success && addCouponResponse?.data?.id) {
            $thisInput.val('');
            $thisInput.prop('disabled', true);
            $parentField.removeClass('is-invalid');
            $couponList.addClass('is-filled').append(`<div class="coupon-item">${coupon}<span class="coupon-cross"></span></div>`);
            $subtotalCost.text(Number(addCouponResponse?.data?.subtotal).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));
            $discountCost.text(Number(addCouponResponse?.data?.coupondiscount).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));
            $couponDiscount.addClass('is-visible');
            $taxCost.text(Number(addCouponResponse?.data?.salestax).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));
            $orderTotalCost.text(Number(addCouponResponse?.data?.total).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));

            // Update basket and payment methods
            await updateBasketAndPaymentMethods(addCouponResponse.data);
          } else {
            console.log(addCouponResponse);
            if (addCouponResponse?.data?.response?.code == 200 && addCouponResponse?.data?.response?.num == 201 && addCouponResponse?.data?.response?.message) {
              $('.coupon-default-text').hide();
              $('.coupon-olo-text').show().text(addCouponResponse?.data?.response?.message);
            } else {
              $('.coupon-default-text').show();
              $('.coupon-olo-text').hide();
            }
            $parentField.addClass('is-invalid');
          }
        }
      };

      // Create debounced version of coupon input handler
      const debouncedCouponInputChangeHandler = debounce(couponInputChangeHandler, 1000);
      $couponInput.on('input', debouncedCouponInputChangeHandler);
      const couponCrossClickHandler = async e => {
        const $thisCross = $(e.target);
        const $thisCouponItem = $thisCross.parents('.coupon-item');
        const $couponInput = $('#coupon');
        const $subtotalCost = $('.subtotal-cost');
        const $discountCost = $('.discount-cost');
        const $couponDiscount = $('.coupon-discount');
        const $taxCost = $('.tax-cost');
        const $orderTotalCost = $('.order-total-cost');
        const coupon = $thisCouponItem.text().trim();
        if (coupon?.length == 0) {
          return false;
        }
        const ajaxData = {
          basket_guid: basketGuid,
          couponcode: coupon
        };
        let removeCouponResponse;
        try {
          removeCouponResponse = await removeCouponFromBasket(ajaxData);
        } catch (error) {
          console.log(error);
        }
        if (removeCouponResponse) {
          if (removeCouponResponse?.success && removeCouponResponse?.data?.id) {
            $thisCouponItem.remove();
            $couponInput.prop('disabled', false);
            $subtotalCost.text(Number(removeCouponResponse?.data?.subtotal).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));
            $discountCost.text(Number(removeCouponResponse?.data?.coupondiscount).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));
            $couponDiscount.removeClass('is-visible');
            $taxCost.text(Number(removeCouponResponse?.data?.salestax).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));
            $orderTotalCost.text(Number(removeCouponResponse?.data?.total).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));

            // Update basket and payment methods
            await updateBasketAndPaymentMethods(removeCouponResponse.data);
          } else {
            console.log(removeCouponResponse);
          }
        }
      };
      $(document).on('click', '.coupon-cross', couponCrossClickHandler);
    };

    // Getting environment and initializing components
    getEnvironment();
  });

}));
