jQuery(function ($) {
  const $orderPage = $('.page-template-page-catering-order');
  const $fourthStep = $('.fourth-step');

  // If the page or fourth step element is not found, exit early
  if ($orderPage.length == 0 && $fourthStep !== 0) return;

  const orderTimer = () => {
    // Get the start date and time in milliseconds
    let startDateAndTimeMs = getStartDate();

    // Set the start date and time in cookies
    Cookies.set('wordpress_catering_start_date_and_time', startDateAndTimeMs);

    // Constants for time calculations
    const oneHourMs = 60 * 60 * 1000;
    const orderingTimeMs = 23.5 * oneHourMs;
    const expireDays = 7;
    const cookiesOptions = { expires: expireDays };

    // Get wanted date and time from cookies, or use the start date as default
    let wantedDateAndTimeMs = Number(
      Cookies.get('wordpress_catering_wanted_date_and_time') ||
        startDateAndTimeMs
    );

    // Get the time to order
    let orderExpirationDateTimeMs = Number(
      Cookies.get('wordpress_catering_ordering_time') || 0
    );

    if (!orderExpirationDateTimeMs) {
      orderExpirationDateTimeMs = wantedDateAndTimeMs - orderingTimeMs;

      // Set the ordering time expiration in cookies
      Cookies.set(
        'wordpress_catering_ordering_time',
        orderExpirationDateTimeMs,
        cookiesOptions
      );
    }

    const currentTimeMs = getCurrentPSTTime().getTime();

    if (orderExpirationDateTimeMs < currentTimeMs) {
      clearCateringCookies();
      clearCateringLocalStorage();
      openInfoModal('timeout');
    }
  };

  orderTimer();

  setInterval(() => {
    orderTimer();
  }, 60 * 1000);
});
