jQuery(function ($) {
  const $orderPage = $('.page-template-page-catering-order');
  const $loadMore = $('.result-load-more');

  if ($orderPage.length == 0 || $loadMore.length == 0) {
    return;
  }

  const loadMoreClickHandler = async (e) => {
    e.preventDefault();

    const $this = $(e.target);
    const cacheName = $this.attr('data-cache-name');
    const $resultList = $('.result-list');

    if (typeof cacheName == 'undefined') {
      return false;
    }

    // Add loader
    toggleCateringLoader(true);

    const ajaxData = {
      cache_name: cacheName,
    };

    const response = await requestNearbyRestaurantsLoadMore(ajaxData);

    // Remove loader
    toggleCateringLoader(false);

    if (!response?.success) {
      return false;
    }

    if (response?.data?.is_last_page) {
      $this.hide();
      Cookies.remove('wordpress_catering_load_more_cache_name');
    }

    $resultList.append(response?.data?.html);

    const expireDays = 7;
    const localStorageExpiryDate = getDateInFuture(expireDays);

    // save restaurants list to localStorage
    ExpiringLocalStorage.set(
      'wordpress_catering_nearby_restaurants',
      $resultList.html(),
      localStorageExpiryDate
    );
  };

  $loadMore.on('click', loadMoreClickHandler);
});
