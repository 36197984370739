jQuery(function ($) {
  const $orderPage = $('.page-template-page-catering-order');
  const $secondStep = $('.second-step');

  // If the page or second step element is not found, exit early
  if ($orderPage.length == 0 || $secondStep.length == 0) return;

  /**
   * Required Inputs handlers
   */
  const $optiongroups = $('.optiongroup');
  const $requiredOptiongroups = $optiongroups.filter('[data-isrequired="yes"]');
  const $inputs = $('input');
  const $submitButton = $('#optionsgroup-form-submit');

  const inputChangeHandler = (e) => {
    const $thisInput = $(e.target);
    const $thisOptiongroup = $thisInput.parents('.optiongroup');
    const minSelects = $thisOptiongroup.data('minselects');
    const maxSelects = $thisOptiongroup.data('maxselects');
    const type = $thisInput.attr('type');
    const $siblingsInputs = $thisInput.parents('.optiongroup').find('input');

    switch (type) {
      case 'radio':
        $siblingsInputs.prop('checked', false).addClass('is-disabled');
        $thisInput.prop('checked', true).removeClass('is-disabled');

        $thisOptiongroup.addClass('is-done');

        break;

      case 'checkbox':
        let checkedCount = 0;

        $siblingsInputs.each(function (_, input) {
          if ($(input).is(':checked')) {
            ++checkedCount;
          }
        });

        const isThisInputChecked = $thisInput.prop('checked');

        if (isThisInputChecked && checkedCount > maxSelects) {
          $thisInput.prop('checked', false);
          --checkedCount;
        }

        const isDisabled = checkedCount == maxSelects;

        $siblingsInputs.not(':checked').prop('disabled', isDisabled);

        if (checkedCount === 0) {
          $thisOptiongroup.removeClass('is-done');
        } else {
          const isAllowedNumberMarked =
            checkedCount >= minSelects && checkedCount <= maxSelects;

          if (isAllowedNumberMarked) {
            if (!$thisOptiongroup.hasClass('is-done')) {
              $thisOptiongroup.addClass('is-done');
            }
          } else {
            $thisOptiongroup.removeClass('is-done');
          }
        }
        break;

      case 'number':
        let addedExtrasCount = 0;

        $siblingsInputs.each(function (_, input) {
          if ($(input).val() > 0) {
            ++addedExtrasCount;
          }
        });

        if ($thisInput.val() > 0) {
          $thisOptiongroup.addClass('is-done');
        } else {
          if (addedExtrasCount == 0) $thisOptiongroup.removeClass('is-done');
        }

        break;

      default:
        break;
    }

    let isReadyToCheckout = true;

    $requiredOptiongroups.each(function (_, element) {
      if (!$(element).hasClass('is-done')) {
        isReadyToCheckout = false;
      }
    });

    $submitButton.prop('disabled', !isReadyToCheckout);
  };

  $inputs.on('change', inputChangeHandler);

  /**
   * Form Submit Handler
   */
  const $form = $('#optionsgroup-form');

  const formSubmitHandler = async (e) => {
    e.preventDefault();

    $submitButton.prop('disabled', true).addClass('is-loading');

    const restaurantID = getRestaurantIDFromUrl();

    const productID =
      Cookies.get(`wordpress_catering_${restaurantID}_product_id`) || '';

    const basketGuid =
      Cookies.get(`wordpress_catering_${restaurantID}_basket_guid`) || '';

    let productOptions = [];

    const formData = new FormData(e.target);

    for (const [key, value] of formData) {
      if (key != 'special_instructions') {
        productOptions.push({ choiceid: Number(key), quantity: Number(value) });
      }
    }

    const savedWantedDateTime = Cookies.get(
      'wordpress_catering_wanted_date_and_time'
    );

    if (typeof savedWantedDateTime === 'undefined') {
      console.log('wanted date and time is undefined');

      return false;
    }

    const wantedDateTimeObj = new Date(Number(savedWantedDateTime));

    const wantedDateTimeOptions = {
      year: wantedDateTimeObj.getFullYear(),
      month: wantedDateTimeObj.getMonth() + 1,
      day: wantedDateTimeObj.getDate(),
      hour: wantedDateTimeObj.getHours(),
      minute: wantedDateTimeObj.getMinutes(),
    };

    const orderType = Cookies.get('wordpress_catering_type');

    if (typeof orderType === 'undefined') {
      console.log('delivery type is undefined');

      return false;
    }

    const ajaxData = {
      basket_guid: basketGuid,
      restaurant_id: restaurantID,
      product_id: productID,
      product_options: productOptions,
      special_instructions: formData.get('special_instructions'),
      delivery_mode: orderType,
      ...wantedDateTimeOptions,
    };

    if (orderType == 'delivery') {
      const addressComponents = ExpiringLocalStorage.get(
        'wordpress_catering_delivery_address_components'
      );

      let deliveryAddressArgs = {};
      let streetNumber = '';
      let route = '';

      if (addressComponents) {
        console.log(addressComponents);

        for (var i = 0; i < addressComponents.length; i++) {
          if (addressComponents[i].types.includes('postal_code')) {
            deliveryAddressArgs.zipcode = addressComponents[i].long_name;
          }

          if (addressComponents[i].types.includes('locality')) {
            deliveryAddressArgs.city = addressComponents[i].long_name;
          }

          if (addressComponents[i].types.includes('street_number')) {
            streetNumber = addressComponents[i].long_name;
          }

          if (addressComponents[i].types.includes('route')) {
            route = addressComponents[i].long_name;
          }
        }

        if (streetNumber.length !== 0 && route !== 0) {
          deliveryAddressArgs.streetaddress = streetNumber + ' ' + route;
        }

        if (deliveryAddressArgs.length !== 0) {
          ajaxData.delivery_address_args = deliveryAddressArgs;
        }
      }
    }

    let response = null;
    let basketValidateResponse = null;

    try {
      response = await createOrUpdateBasket(ajaxData);
      
      // Check that the basket was created/updated successfully
      if (response && response?.success && response?.data?.id) {
        // Add basket validation after successful creation/update
        const validateBasketOptions = {
          basket_guid: response.data.id
        };
        
        try {
          basketValidateResponse = await validateBasket(validateBasketOptions);
        } catch (error) {
          console.log('Basket validation error:', error);
          
          openInfoModal('error', {
            title: 'Basket Validation Error',
            message: 'An error occurred while validating your basket. Please try again.',
          });
          
          $submitButton.prop('disabled', false).removeClass('is-loading');
          return false;
        }
        
        // Check the basket validation result
        if (basketValidateResponse && basketValidateResponse?.success === false) {
          console.log('Basket validation failed:', basketValidateResponse);
          
          openInfoModal('error', {
            title: 'Basket Validation Error',
            message: basketValidateResponse?.data?.response?.message || 'Your basket is invalid. Please check your selected options.',
          });
          
          $submitButton.prop('disabled', false).removeClass('is-loading');
          return false;
        }
        
        // If the basket passed validation, continue processing
        const expireDays = 7;
        const localStorageExpiryDate = getDateInFuture(expireDays);

        ExpiringLocalStorage.set(
          `wordpress_catering_${restaurantID}_basket`,
          response.data,
          localStorageExpiryDate
        );

        window.location.href = new URL(
          window.location.search + '&basket_guid=' + response.data.id,
          window.location.href
        );
      } else {
        console.log('Create/update basket failed:', response?.data);
        
        if (response?.data?.response?.code == 200) {
          openInfoModal('error', {
            title: `Request: "${response?.data?.type}" - is failed`,
            message: response?.data?.response?.message,
          });
        }
      }
    } catch (error) {
      console.log(error);

      if (response?.data?.response?.code == 200) {
        clearCateringCookies();
        clearCateringLocalStorage();

        openInfoModal('error', {
          title: `Request: "${response?.data?.type}" - is failed`,
          message: response?.data?.response?.message,
        });
      }
    }

    $submitButton.prop('disabled', false).removeClass('is-loading');
  };

  $form.on('submit', formSubmitHandler);
});
