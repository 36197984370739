jQuery(function ($) {
  const $orderPage = $('.page-template-page-catering-order');
  const $firstStep = $('.first-step');

  // If the page or first step element is not found, exit early
  if ($orderPage.length == 0 || $firstStep.length == 0) return;

  // Cache jQuery selections
  const $tabName = $('.tabs .tabs-name', $orderPage);
  const $tabForm = $('.tabs .tabs-form', $orderPage);
  const $forms = $('.forms');
  const $result = $('.result');
  const $resultContainer = $('.result-container');

  let isMobile = $(window).width() < 1024;

  // Initialize page state
  if (!isMobile) {
    setTimeout(() => {
      $forms.removeClass('is-hidden-mob');
      const activeType = $('.tabs-name.is-active').data('type');
      const nrbRestaurantsHtml = ExpiringLocalStorage.get(
        'wordpress_catering_nearby_restaurants'
      );
      if (activeType === 'pickup' && nrbRestaurantsHtml) return;
    }, 0);
  }

  const updateResultsVisibility = (type, isMobileView) => {
    const nrbRestaurantsHtml = ExpiringLocalStorage.get(
      'wordpress_catering_nearby_restaurants'
    );

    if (type === 'pickup' && nrbRestaurantsHtml) {
      if (!isMobileView) {
        $result.addClass('result-visible-list');
        $forms.addClass('is-hidden-mob');
        $resultContainer.show();
      } else {
        $result.removeClass('result-visible-list');
        $forms.removeClass('is-hidden-mob');
        $resultContainer.hide();
      }
    } else {
      $result.removeClass('result-visible-list');
      $forms.removeClass('is-hidden-mob');
      $resultContainer.hide();
    }
  };

  const tabClickHandler = function () {
    const $thisTab = $(this);
    const type = $thisTab.data('type');
    if ($thisTab.hasClass('is-active')) return false;

    $tabName.removeClass('is-active');
    $thisTab.addClass('is-active');
    $tabForm
      .removeClass('is-active')
      .filter(`[data-type="${type}"]`)
      .addClass('is-active');

    updateResultsVisibility(type, isMobile);
  };

  const handleResize = function () {
    const newIsMobile = $(window).width() < 1024;
    if (newIsMobile !== isMobile) {
      if (!newIsMobile) {
        $forms.removeClass('is-hidden-mob');
      }
      const activeType = $('.tabs-name.is-active').data('type');
      updateResultsVisibility(activeType, newIsMobile);
    }
    isMobile = newIsMobile;
  };

  // Throttle resize handler
  let resizeTimeout;
  $(window).on('resize', function () {
    if (!resizeTimeout) {
      resizeTimeout = setTimeout(function () {
        handleResize();
        resizeTimeout = null;
      }, 250);
    }
  });

  $tabName.on('click', tabClickHandler);
});
