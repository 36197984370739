jQuery(function ($) {
  const $orderPage = $('.page-template-page-catering-order');
  const $thirdStep = $('.third-step');

  // If the page or third step element is not found, exit early
  if ($orderPage.length == 0 || $thirdStep.length == 0) return;

  /**
   * User Fields
   */

  const $requiredInputs = $('input.is-required');
  const $oloFormBtn = $('.olo-form-button');
  const $paymentsButtons = $('.payment-buttons');

  const requiredInputsChangeHandler = (e) => {
    const $thisInput = $(e.target);

    let isButtonsDisabled = false;

    if (validateInput($thisInput) == false) {
      $thisInput.parents('.ct-field').addClass('is-invalid');
    } else {
      $thisInput.parents('.ct-field').removeClass('is-invalid');

      // Check all required inputs
      $requiredInputs.each(function (_, input) {
        if (validateInput($(input)) == false) {
          isButtonsDisabled = true;
        }
      });
    }

    if (!isButtonsDisabled) {
      $paymentsButtons.removeClass('is-disabled');
      $oloFormBtn.prop('disabled', false);
    }
  };

  $requiredInputs.on('change', requiredInputsChangeHandler);

  $('#phone-number').mask('000-000-0000');

  const $creditCardBtn = $('.credit-card-button');

  const creditCardBtnHandler = (e) => {
    $(e.target).toggleClass('is-active');
    $('.credit-card').toggleClass('is-hidden');
  };

  $creditCardBtn.on('click', creditCardBtnHandler);
});
