async function requestNearbyRestaurants(params) {
  const data = {
    action: 'olo_nearby_restaurants',
    nonce: tacoVars.ajaxNonce,
    ...params,
  };

  return new Promise((resolve, reject) => {
    $.ajax({
      url: tacoVars.ajaxurl,
      method: 'POST',
      data: data,
      success: function (response) {
        resolve(response);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        reject(errorThrown);
      },
    });
  });
}

async function requestNearbyRestaurantsLoadMore(params) {
  const data = {
    action: 'olo_nearby_restaurants_load_more',
    nonce: tacoVars.ajaxNonce,
    ...params,
  };

  return new Promise((resolve, reject) => {
    $.ajax({
      url: tacoVars.ajaxurl,
      method: 'POST',
      data: data,
      success: function (response) {
        resolve(response);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        reject(errorThrown);
      },
    });
  });
}

async function setMenuToTransient(params) {
  const data = {
    action: 'olo_set_menu_to_transient',
    nonce: tacoVars.ajaxNonce,
    ...params,
  };

  return new Promise((resolve, reject) => {
    $.ajax({
      url: tacoVars.ajaxurl,
      method: 'POST',
      data: data,
      success: function (response) {
        resolve(response);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        reject(errorThrown);
      },
    });
  });
}

async function getProductsOptions(params) {
  const data = {
    action: 'olo_products_options',
    nonce: tacoVars.ajaxNonce,
    ...params,
  };

  return new Promise((resolve, reject) => {
    $.ajax({
      url: tacoVars.ajaxurl,
      method: 'POST',
      data: data,
      success: function (response) {
        resolve(response);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        reject(errorThrown);
      },
    });
  });
}

// Check the availability of the restaurant for ordering
async function checkRestaurantAvailability(params) {
  // restaurantID, from, to
  return new Promise((resolve, reject) => {
    $.ajax({
      url: tacoVars.ajaxurl,
      method: 'POST',
      data: params.data,
      success: function (response) {
        resolve(response);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        reject(errorThrown);
      },
    });
  });
}

async function chooseRestaurant(addressCoords, params) {
  /**
   * To get an array of restaurants and an array of delivery zones,
   * you need to export the KMZ file from the custom map.
   *
   * Then you need to convert this file to geojson using https://products.aspose.app/gis/conversion/kmz-to-json.
   *
   * Then take the restaurants and delivery zones from the resulting file and add them to the variables of the same name.
   *
   * !!! IMPORTANT !!!: The names of the restaurants and the names of their delivery zones must match.
   */

  const restaurants = [
    {
      type: 'Feature',
      geometry: null,
      properties: {
        name: 'Bellingham - Bakerview',
        address:
          'Bellingham - Bakerview 4100 Arctic Avenue Bellingham WA 98226',
        description:
          'Address: 4100 Arctic Avenue<br>City: Bellingham<br>State: WA<br>Zip: 98226',
        Address: '4100 Arctic Avenue',
        City: 'Bellingham',
        State: 'WA',
        Zip: '98226',
      },
    },
    {
      type: 'Feature',
      geometry: null,
      properties: {
        name: 'Stanwood',
        address: 'Stanwood 26479 72nd Ave NW Stanwood WA 98292',
        description:
          'Address: 26479 72nd Ave NW<br>City: Stanwood<br>State: WA<br>Zip: 98292',
        Address: '26479 72nd Ave NW',
        City: 'Stanwood',
        State: 'WA',
        Zip: '98292',
      },
    },
    {
      type: 'Feature',
      geometry: null,
      properties: {
        name: 'Wenatchee',
        address: 'Wenatchee 1512 N. Wenatchee Ave. Wenatchee WA 98801',
        description:
          'Address: 1512 N. Wenatchee Ave.<br>City: Wenatchee<br>State: WA<br>Zip: 98801',
        Address: '1512 N. Wenatchee Ave.',
        City: 'Wenatchee',
        State: 'WA',
        Zip: '98801',
      },
    },
    {
      type: 'Feature',
      geometry: null,
      properties: {
        name: 'Marysville - Tulalip',
        address: '6325 33rd Ave. N.E. Marysville WA 98271',
        description:
          'Address: 6325 33rd Ave. N.E.<br>City: Marysville<br>State: WA<br>Zip: 98271',
        Address: '6325 33rd Ave. N.E.',
        City: 'Marysville',
        State: 'WA',
        Zip: '98271',
      },
    },
    {
      type: 'Feature',
      geometry: null,
      properties: {
        name: 'Snohomish',
        address: '818 Ave. D. Snohomish WA 98290',
        description:
          'Address: 818 Ave. D.<br>City: Snohomish<br>State: WA<br>Zip: 98290',
        Address: '818 Ave. D.',
        City: 'Snohomish',
        State: 'WA',
        Zip: '98290',
      },
    },
    {
      type: 'Feature',
      geometry: null,
      properties: {
        name: 'Woodinville',
        address: '13912 N.E. 175th. St. Woodinville WA 98072',
        description:
          'Address: 13912 N.E. 175th. St.<br>City: Woodinville<br>State: WA<br>Zip: 98072',
        Address: '13912 N.E. 175th. St.',
        City: 'Woodinville',
        State: 'WA',
        Zip: '98072',
      },
    },
    {
      type: 'Feature',
      geometry: null,
      properties: {
        name: 'Kent - East Hill',
        address: '25649 104th. Ave. S.E. Kent WA 98030',
        description:
          'Address: 25649 104th. Ave. S.E.<br>City: Kent<br>State: WA<br>Zip: 98030',
        Address: '25649 104th. Ave. S.E.',
        City: 'Kent',
        State: 'WA',
        Zip: '98030',
      },
    },
    {
      type: 'Feature',
      geometry: null,
      properties: {
        name: 'Maple Valley',
        address: '23315 MV-Black Diamond Rd SE Maple Valley WA 98038',
        description:
          'Address: 23315 MV-Black Diamond Rd SE<br>City: Maple Valley<br>State: WA<br>Zip: 98038',
        Address: '23315 MV-Black Diamond Rd SE',
        City: 'Maple Valley',
        State: 'WA',
        Zip: '98038',
      },
    },
    {
      type: 'Feature',
      geometry: null,
      properties: {
        name: 'Tukwila - International Blvd.',
        address: '15037 Tukwila Intl. Blvd. Tukwila WA 98188',
        description:
          'Address: 15037 Tukwila Intl. Blvd.<br>City: Tukwila<br>State: WA<br>Zip: 98188',
        Address: '15037 Tukwila Intl. Blvd.',
        City: 'Tukwila',
        State: 'WA',
        Zip: '98188',
      },
    },
    {
      type: 'Feature',
      geometry: null,
      properties: {
        name: 'Fife',
        address: '5105 Pacific Hwy E. Fife WA 98424',
        description:
          'Address: 5105 Pacific Hwy E.<br>City: Fife<br>State: WA<br>Zip: 98424',
        Address: '5105 Pacific Hwy E.',
        City: 'Fife',
        State: 'WA',
        Zip: '98424',
      },
    },
    {
      type: 'Feature',
      geometry: null,
      properties: {
        name: 'Port Angeles',
        address: '1105 E. 1st. St. Port Angeles WA 98362',
        description:
          'Address: 1105 E. 1st. St.<br>City: Port Angeles<br>State: WA<br>Zip: 98362',
        Address: '1105 E. 1st. St.',
        City: 'Port Angeles',
        State: 'WA',
        Zip: '98362',
      },
    },
    {
      type: 'Feature',
      geometry: null,
      properties: {
        name: 'Puyallup - East Main',
        address: '1115 E. Main Puyallup WA 98372',
        description:
          'Address: 1115 E. Main<br>City: Puyallup<br>State: WA<br>Zip: 98372',
        Address: '1115 E. Main',
        City: 'Puyallup',
        State: 'WA',
        Zip: '98372',
      },
    },
    {
      type: 'Feature',
      geometry: null,
      properties: {
        name: 'Puyallup - Frederickson',
        address: '5606 176th St. E. Puyallup WA 98375',
        description:
          'Address: 5606 176th St. E.<br>City: Puyallup<br>State: WA<br>Zip: 98375',
        Address: '5606 176th St. E.',
        City: 'Puyallup',
        State: 'WA',
        Zip: '98375',
      },
    },
    {
      type: 'Feature',
      geometry: null,
      properties: {
        name: 'Silverdale',
        address: '3141 NW Bucklin Hill Road Silverdale WA 98383',
        description:
          'Address: 3141 NW Bucklin Hill Road<br>City: Silverdale<br>State: WA<br>Zip: 98383',
        Address: '3141 NW Bucklin Hill Road',
        City: 'Silverdale',
        State: 'WA',
        Zip: '98383',
      },
    },
    {
      type: 'Feature',
      geometry: null,
      properties: {
        name: 'Edmonds',
        address: '23904 Highway 99 Edmonds WA 98026',
        description:
          'Address: 23904 Highway 99<br>City: Edmonds<br>State: WA<br>Zip: 98026',
        Address: '23904 Highway 99',
        City: 'Edmonds',
        State: 'WA',
        Zip: '98026',
      },
    },
    {
      type: 'Feature',
      geometry: null,
      properties: {
        name: 'Seattle - Ballard',
        address: '2853 N.W. Market St. Seattle WA 98107',
        description:
          'Address: 2853 N.W. Market St.<br>City: Seattle<br>State: WA<br>Zip: 98107',
        Address: '2853 N.W. Market St.',
        City: 'Seattle',
        State: 'WA',
        Zip: '98107',
      },
    },
    {
      type: 'Feature',
      geometry: null,
      properties: {
        name: 'Seattle - Rainier',
        address: '2212 Rainier Ave. So. Seattle WA 98144',
        description:
          'Address: 2212 Rainier Ave. So.<br>City: Seattle<br>State: WA<br>Zip: 98144',
        Address: '2212 Rainier Ave. So.',
        City: 'Seattle',
        State: 'WA',
        Zip: '98144',
      },
    },
    {
      type: 'Feature',
      geometry: null,
      properties: {
        name: 'Bellevue - Factoria',
        address: '3920 Factoria Blvd. S.E. Bellevue WA 98006',
        description:
          'Address: 3920 Factoria Blvd. S.E.<br>City: Bellevue<br>State: WA<br>Zip: 98006',
        Address: '3920 Factoria Blvd. S.E.',
        City: 'Bellevue',
        State: 'WA',
        Zip: '98006',
      },
    },
    {
      type: 'Feature',
      geometry: null,
      properties: {
        name: 'Issaquah - Gilman',
        address: '1125 Gilman Blvd. STE E-2 Issaquah WA 98027',
        description:
          'Address: 1125 Gilman Blvd. STE E-2<br>City: Issaquah<br>State: WA<br>Zip: 98027',
        Address: '1125 Gilman Blvd. STE E-2',
        City: 'Issaquah',
        State: 'WA',
        Zip: '98027',
      },
    },
    {
      type: 'Feature',
      geometry: null,
      properties: {
        name: 'Lynnwood - Martha Lake',
        address: '1010 164th St. S.W. Lynnwood WA 98087',
        description:
          'Address: 1010 164th St. S.W.<br>City: Lynnwood<br>State: WA<br>Zip: 98087',
        Address: '1010 164th St. S.W.',
        City: 'Lynnwood',
        State: 'WA',
        Zip: '98087',
      },
    },
    {
      type: 'Feature',
      geometry: null,
      properties: {
        name: 'Renton - Highlands',
        address: '4114 N.E. 4th. St. Renton WA 98059',
        description:
          'Address: 4114 N.E. 4th. St.<br>City: Renton<br>State: WA<br>Zip: 98059',
        Address: '4114 N.E. 4th. St.',
        City: 'Renton',
        State: 'WA',
        Zip: '98059',
      },
    },
  ];

  const cateringAreas = [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1970421, 47.5561212, 0],
            [-122.2106893, 47.5181091, 0],
            [-122.2153216, 47.5003674, 0],
            [-122.2119768, 47.489988, 0],
            [-122.1945973, 47.4847784, 0],
            [-122.1816785, 47.4783292, 0],
            [-122.1454525, 47.4682466, 0],
            [-122.1070059, 47.4303908, 0],
            [-122.0363626, 47.4401764, 0],
            [-122.0511708, 47.4907015, 0],
            [-122.0701622, 47.5586571, 0],
            [-122.1970421, 47.5561212, 0],
          ],
        ],
      },
      properties: {
        name: 'Renton - Highlands',
        description: 'Address: <br>City: <br>State: <br>Zip: ',
        Address: '',
        City: '',
        State: '',
        Zip: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3995283, 47.8046066, 0],
            [-122.3991849, 47.7705746, 0],
            [-122.3816311, 47.7124388, 0],
            [-122.3327928, 47.7129735, 0],
            [-122.2730844, 47.7059236, 0],
            [-122.2679882, 47.7388111, 0],
            [-122.2499589, 47.7554183, 0],
            [-122.2505159, 47.769511, 0],
            [-122.2514245, 47.7813035, 0],
            [-122.2093663, 47.8063324, 0],
            [-122.2910587, 47.8128694, 0],
            [-122.3107839, 47.8172989, 0],
            [-122.3294886, 47.8215005, 0],
            [-122.3622668, 47.83208, 0],
            [-122.3995283, 47.8046066, 0],
          ],
        ],
      },
      properties: { name: 'Edmonds' },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3816311, 47.7124388, 0],
            [-122.4404965, 47.6552508, 0],
            [-122.3900345, 47.6233319, 0],
            [-122.3241468, 47.6235675, 0],
            [-122.2582156, 47.6237942, 0],
            [-122.2496774, 47.644767, 0],
            [-122.236589, 47.6746705, 0],
            [-122.2496337, 47.690848, 0],
            [-122.2730844, 47.7059236, 0],
            [-122.3327928, 47.7129735, 0],
            [-122.3816311, 47.7124388, 0],
          ],
        ],
      },
      properties: { name: 'Seattle - Ballard' },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3900345, 47.6233319, 0],
            [-122.4245847, 47.5899705, 0],
            [-122.4355714, 47.5770017, 0],
            [-122.4142845, 47.5190661, 0],
            [-122.3844145, 47.4947121, 0],
            [-122.3490508, 47.4956404, 0],
            [-122.264254, 47.5050495, 0],
            [-122.231628, 47.5083986, 0],
            [-122.2051309, 47.5411547, 0],
            [-122.2183435, 47.561549, 0],
            [-122.2317382, 47.5822893, 0],
            [-122.2380532, 47.5921163, 0],
            [-122.2449716, 47.6026953, 0],
            [-122.2582156, 47.6237942, 0],
            [-122.3900345, 47.6233319, 0],
          ],
        ],
      },
      properties: { name: 'Seattle - Rainier' },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.042631, 47.971152, 0],
            [-122.1199444, 47.9760697, 0],
            [-122.1625164, 47.9790575, 0],
            [-122.1881031, 47.987102, 0],
            [-122.2053492, 47.9923873, 0],
            [-122.2139734, 47.9952586, 0],
            [-122.2225979, 47.9949128, 0],
            [-122.2404516, 47.9785957, 0],
            [-122.2466306, 47.9680249, 0],
            [-122.2339276, 47.9137471, 0],
            [-122.2006253, 47.8789886, 0],
            [-122.175207, 47.8514625, 0],
            [-122.1597699, 47.8324539, 0],
            [-122.099668, 47.8239625, 0],
            [-122.0447304, 47.8179814, 0],
            [-121.9362668, 47.806404, 0],
            [-121.9115475, 47.875995, 0],
            [-121.9218472, 47.9721624, 0],
            [-121.9823255, 47.9715581, 0],
            [-122.042631, 47.971152, 0],
          ],
        ],
      },
      properties: { name: 'Snohomish' },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1597699, 47.8324539, 0],
            [-122.2093663, 47.8063324, 0],
            [-122.2514245, 47.7813035, 0],
            [-122.2504769, 47.7685195, 0],
            [-122.2499589, 47.7554183, 0],
            [-122.2679882, 47.7388111, 0],
            [-122.2581807, 47.7148974, 0],
            [-122.2091083, 47.6597304, 0],
            [-122.1407605, 47.6602589, 0],
            [-122.0700081, 47.660342, 0],
            [-121.9164257, 47.6523872, 0],
            [-121.9362668, 47.806404, 0],
            [-122.0486769, 47.8184458, 0],
            [-122.0741715, 47.8209189, 0],
            [-122.099668, 47.8239625, 0],
            [-122.1597699, 47.8324539, 0],
          ],
        ],
      },
      properties: { name: 'Woodinville' },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2091083, 47.6597304, 0],
            [-122.2309835, 47.6534359, 0],
            [-122.2496774, 47.644767, 0],
            [-122.2499349, 47.6105802, 0],
            [-122.2319011, 47.5831973, 0],
            [-122.2145724, 47.5556959, 0],
            [-122.0701622, 47.5586571, 0],
            [-122.074415, 47.5735369, 0],
            [-122.0903049, 47.5865982, 0],
            [-122.0840045, 47.6062398, 0],
            [-122.0807667, 47.6160605, 0],
            [-122.0773572, 47.6255914, 0],
            [-122.1177547, 47.6603201, 0],
            [-122.2091083, 47.6597304, 0],
          ],
        ],
      },
      properties: { name: 'Bellevue - Factoria' },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2466306, 47.9680249, 0],
            [-122.330216, 47.9536996, 0],
            [-122.3622668, 47.83208, 0],
            [-122.3294886, 47.8215005, 0],
            [-122.2910587, 47.8128694, 0],
            [-122.2093663, 47.8063324, 0],
            [-122.1597699, 47.8324539, 0],
            [-122.2182409, 47.8983389, 0],
            [-122.2339276, 47.9137471, 0],
            [-122.2466306, 47.9680249, 0],
          ],
        ],
      },
      properties: { name: 'Lynnwood - Martha Lake' },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.0700081, 47.660342, 0],
            [-122.1168971, 47.6599146, 0],
            [-122.0773572, 47.6255914, 0],
            [-122.0903049, 47.5865982, 0],
            [-122.074415, 47.5735369, 0],
            [-122.0363625, 47.4402054, 0],
            [-121.7706307, 47.435561, 0],
            [-121.7417915, 47.507503, 0],
            [-121.9275721, 47.6529708, 0],
            [-122.0700081, 47.660342, 0],
          ],
        ],
      },
      properties: { name: 'Issaquah - Gilman' },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3480166, 48.1015381, 0],
            [-122.2724856, 48.0152584, 0],
            [-122.1625164, 47.9790575, 0],
            [-122.042631, 47.971152, 0],
            [-121.9817054, 47.9716159, 0],
            [-121.9218472, 47.9721624, 0],
            [-121.9772281, 48.1180433, 0],
            [-121.9817244, 48.1446351, 0],
            [-122.0464905, 48.1446302, 0],
            [-122.1722353, 48.1372926, 0],
            [-122.3639252, 48.1189663, 0],
            [-122.349089, 48.115732, 0],
            [-122.3480166, 48.1015381, 0],
          ],
        ],
      },
      properties: { name: 'Marysville - Tulalip' },
    },
    {
      type: 'Feature',
      id: '',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1588374, 49.0024468, 0],
            [-122.7836849, 49.0051495, 0],
            [-122.8482295, 48.9438529, 0],
            [-122.6642085, 48.7187555, 0],
            [-122.6161434, 48.7160373, 0],
            [-122.5667049, 48.7631317, 0],
            [-122.5255061, 48.7386844, 0],
            [-122.5406124, 48.6071895, 0],
            [-122.1864108, 48.6132685, 0],
            [-122.1588374, 49.0024468, 0],
          ],
        ],
      },
      properties: {
        name: 'Bellingham - Bakerview',
        description: '',
        shape_area: '',
        shape_len: '',
        zip: '',
      },
    },
    {
      type: 'Feature',
      id: '',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5406124, 48.6071895, 0],
            [-122.5661257, 48.5160259, 0],
            [-122.7048281, 48.5205742, 0],
            [-122.6842288, 48.4605049, 0],
            [-122.5537661, 48.4076581, 0],
            [-122.5208071, 48.3419786, 0],
            [-122.4631289, 48.2798702, 0],
            [-122.5455264, 48.2551885, 0],
            [-122.5468997, 48.1471806, 0],
            [-122.4768618, 48.0949257, 0],
            [-122.3823489, 48.100621, 0],
            [-122.3858093, 48.2348429, 0],
            [-122.3639252, 48.1189663, 0],
            [-122.1722353, 48.1372926, 0],
            [-122.0478042, 48.1446687, 0],
            [-121.9817244, 48.1446351, 0],
            [-122.0052918, 48.2325227, 0],
            [-122.0415286, 48.3939818, 0],
            [-122.1864108, 48.6132685, 0],
            [-122.5406124, 48.6071895, 0],
          ],
        ],
      },
      properties: {
        name: 'Stanwood',
        description: '',
        shape_area: '',
        shape_len: '',
        zip: '',
      },
    },
    {
      type: 'Feature',
      id: '',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3432709, 47.1607775, 0],
            [-122.4123167, 47.1635552, 0],
            [-122.4884798, 47.160076, 0],
            [-122.5437453, 47.1194432, 0],
            [-122.435955, 47.0687287, 0],
            [-122.3748495, 47.0090722, 0],
            [-122.2461173, 47.00837, 0],
            [-122.1927135, 47.0920882, 0],
            [-122.2341983, 47.1600411, 0],
            [-122.3033286, 47.1596007, 0],
            [-122.3432709, 47.1607775, 0],
          ],
        ],
      },
      properties: {
        name: 'Puyallup - Frederickson',
        description: '',
        shape_area: '',
        shape_len: '',
        zip: '',
      },
    },
    {
      type: 'Feature',
      id: '',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2649759, 47.3015158, 0],
            [-122.2613174, 47.3146483, 0],
            [-122.2604507, 47.3276848, 0],
            [-122.2618444, 47.3352584, 0],
            [-122.2876397, 47.3344849, 0],
            [-122.3145801, 47.3342798, 0],
            [-122.3549466, 47.3342722, 0],
            [-122.3686485, 47.3343213, 0],
            [-122.3949656, 47.328957, 0],
            [-122.5509574, 47.3082966, 0],
            [-122.5847268, 47.2307808, 0],
            [-122.6357085, 47.1541442, 0],
            [-122.4123167, 47.1635552, 0],
            [-122.3521338, 47.2315967, 0],
            [-122.3369314, 47.2324778, 0],
            [-122.2817455, 47.2356811, 0],
            [-122.2774637, 47.2498935, 0],
            [-122.2724862, 47.2736652, 0],
            [-122.2649759, 47.3015158, 0],
          ],
        ],
      },
      properties: {
        name: 'FIfe',
        description: '',
        shape_area: '',
        shape_len: '',
        zip: '',
      },
    },
    {
      type: 'Feature',
      id: '',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2153161, 47.4674092, 0],
            [-122.2511378, 47.4003175, 0],
            [-122.2726017, 47.3819045, 0],
            [-122.2642146, 47.3518955, 0],
            [-122.2604507, 47.3276848, 0],
            [-122.2613174, 47.3146483, 0],
            [-122.2724862, 47.2736652, 0],
            [-122.1798071, 47.2771621, 0],
            [-122.1629806, 47.2769047, 0],
            [-122.1492262, 47.3211055, 0],
            [-122.1472549, 47.3296515, 0],
            [-122.133605, 47.358905, 0],
            [-122.1070059, 47.4303908, 0],
            [-122.1454525, 47.4682466, 0],
            [-122.1945973, 47.4847784, 0],
            [-122.2153161, 47.4674092, 0],
          ],
        ],
      },
      properties: {
        name: 'Kent - East Hill',
        description: '',
        shape_area: '',
        shape_len: '',
        zip: '',
      },
    },
    {
      type: 'Feature',
      id: '',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2817455, 47.2356811, 0],
            [-122.3521338, 47.2315967, 0],
            [-122.3808409, 47.1992147, 0],
            [-122.4123167, 47.1635552, 0],
            [-122.2999408, 47.1595204, 0],
            [-122.2598581, 47.1598463, 0],
            [-122.2341983, 47.1600411, 0],
            [-122.2286187, 47.1508231, 0],
            [-122.1437386, 47.1526309, 0],
            [-122.1306903, 47.1661717, 0],
            [-122.1286305, 47.1783075, 0],
            [-122.1488122, 47.2773728, 0],
            [-122.2724862, 47.2736652, 0],
            [-122.2764309, 47.2537411, 0],
            [-122.2775882, 47.2492189, 0],
            [-122.2790028, 47.2446674, 0],
            [-122.2817455, 47.2356811, 0],
          ],
        ],
      },
      properties: {
        name: 'Puyallup - East Main',
        description: '',
        shape_area: '',
        shape_len: '',
        zip: '',
      },
    },
    {
      type: 'Feature',
      id: '',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.1070059, 47.4303908, 0],
            [-122.1325317, 47.3608659, 0],
            [-122.1470817, 47.3290289, 0],
            [-122.1629806, 47.2769047, 0],
            [-121.9651826, 47.2865389, 0],
            [-121.951104, 47.4388809, 0],
            [-122.0363626, 47.4401764, 0],
            [-122.1070059, 47.4303908, 0],
          ],
        ],
      },
      properties: {
        name: 'Maple Valley',
        description: '',
        shape_area: '',
        shape_len: '',
        zip: '',
      },
    },
    {
      type: 'Feature',
      id: '',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6927132, 47.7044162, 0],
            [-122.7024326, 47.6797427, 0],
            [-122.7283128, 47.6759569, 0],
            [-122.7543609, 47.6710092, 0],
            [-122.7497048, 47.6264974, 0],
            [-122.7117227, 47.5127766, 0],
            [-122.6629074, 47.5159129, 0],
            [-122.6285545, 47.5135267, 0],
            [-122.6003595, 47.5134589, 0],
            [-122.5064697, 47.5379937, 0],
            [-122.5478445, 47.5946325, 0],
            [-122.6156883, 47.5500453, 0],
            [-122.6471196, 47.5412753, 0],
            [-122.6632619, 47.5410594, 0],
            [-122.6766549, 47.5331862, 0],
            [-122.6701354, 47.5452457, 0],
            [-122.6590094, 47.5538824, 0],
            [-122.6540982, 47.5654789, 0],
            [-122.6087363, 47.5663171, 0],
            [-122.5890701, 47.5948642, 0],
            [-122.5955823, 47.6042465, 0],
            [-122.5855965, 47.6422292, 0],
            [-122.6182122, 47.7069577, 0],
            [-122.6487862, 47.7721368, 0],
            [-122.7052575, 47.7769227, 0],
            [-122.6917438, 47.7667618, 0],
            [-122.6899073, 47.7480617, 0],
            [-122.6927132, 47.7044162, 0],
          ],
        ],
      },
      properties: {
        name: 'Silverdale',
        description: '',
        shape_area: '',
        shape_len: '',
        zip: '',
      },
    },
    {
      type: 'Feature',
      id: '',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3665578, 47.4931225, 0],
            [-122.3871572, 47.4462425, 0],
            [-122.3382231, 47.4019637, 0],
            [-122.3304271, 47.3520415, 0],
            [-122.3672444, 47.3342112, 0],
            [-122.2618444, 47.3352584, 0],
            [-122.2726017, 47.3819045, 0],
            [-122.244562, 47.4121536, 0],
            [-122.2172281, 47.4521895, 0],
            [-122.2201056, 47.46575, 0],
            [-122.2071855, 47.4715366, 0],
            [-122.1956342, 47.48498, 0],
            [-122.2122787, 47.4896171, 0],
            [-122.2157081, 47.5000529, 0],
            [-122.231628, 47.5083986, 0],
            [-122.3665578, 47.4931225, 0],
          ],
        ],
      },
      properties: {
        name: 'Tukwila - International Blvd.',
        description: '',
        shape_area: '',
        shape_len: '',
        zip: '',
      },
    },
    {
      type: 'Feature',
      id: '',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.5620064, 48.1477319, 0],
            [-123.566813, 48.1037317, 0],
            [-123.3621926, 48.0697892, 0],
            [-123.0223031, 48.0495966, 0],
            [-123.0435891, 48.1381101, 0],
            [-123.1596322, 48.1637642, 0],
            [-123.250956, 48.1261948, 0],
            [-123.5620064, 48.1477319, 0],
          ],
        ],
      },
      properties: {
        name: 'Port Angeles',
        description: '',
        shape_area: '',
        shape_len: '',
        zip: '',
      },
    },
    {
      type: 'Feature',
      id: '',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.2111019, 47.6917503, 0],
            [-120.4454195, 47.6785242, 0],
            [-120.7560247, 47.6113781, 0],
            [-120.4678203, 47.4219524, 0],
            [-120.4097176, 47.3584131, 0],
            [-120.0983339, 47.321067, 0],
            [-120.0664469, 47.3435346, 0],
            [-120.0317949, 47.4013055, 0],
            [-120.0594422, 47.663891, 0],
            [-120.2111019, 47.6917503, 0],
          ],
        ],
      },
      properties: {
        name: 'Wenatchee',
        description: '',
        shape_area: '',
        shape_len: '',
        zip: '',
      },
    },
  ];

  const ajaxParams = {
    radius: 1,
    limit: 1,
    ...params,
  };

  let result = null;
  let areaName = null;

  for (const area of cateringAreas) {
    if (!area?.geometry || area?.geometry?.type !== 'Polygon') {
      continue;
    }

    const areaCoords = area?.geometry?.coordinates[0].map((crd) => ({
      lat: crd[1],
      lng: crd[0],
    }));

    const isWithinArea = checkAddressInArea(addressCoords, areaCoords);

    if (isWithinArea) {
      areaName = area?.properties?.name;
      break;
    }
  }

  if (areaName) {
    const restaurant = restaurants.filter(
      (restaurant) => areaName == restaurant?.properties?.name
    );

    if (restaurant.length != 0) {
      // result = restaurant[0];

      const restaurantAddress = restaurant[0]?.properties?.address;
      let geocodeResponse = null;

      try {
        geocodeResponse = await getGeocodeByAddress(restaurantAddress);
      } catch (error) {
        console.log(error);
      }

      if (geocodeResponse) {
        const addressCoords = geocodeResponse?.geometry?.location
          .toString()
          .replace(/(\(|\))+/g, '')
          .split(', ');

        ajaxParams.lat = parseFloat(addressCoords[0]);
        ajaxParams.lng = parseFloat(addressCoords[1]);

        let response = null;

        try {
          response = await requestNearbyRestaurants(ajaxParams);
        } catch (error) {
          console.log(error);
        }

        if (response?.success) {
          result = response?.data?.json[0];
        }
      }
    }
  }

  return result;
}

function applyCouponToBasket(params) {
  const data = {
    action: 'olo_apply_coupon_to_basket',
    nonce: tacoVars.ajaxNonce,
    ...params,
  };

  return new Promise((resolve, reject) => {
    $.ajax({
      url: tacoVars.ajaxurl,
      method: 'POST',
      data: data,
      success: function (response) {
        resolve(response);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        reject(errorThrown);
      },
    });
  });
}

function removeCouponFromBasket(params) {
  const data = {
    action: 'olo_remove_coupon_from_basket',
    nonce: tacoVars.ajaxNonce,
    ...params,
  };

  return new Promise((resolve, reject) => {
    $.ajax({
      url: tacoVars.ajaxurl,
      method: 'POST',
      data: data,
      success: function (response) {
        resolve(response);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        reject(errorThrown);
      },
    });
  });
}

function createOrUpdateBasket(params) {
  const data = {
    action: 'olo_create_or_update_basket',
    nonce: tacoVars.ajaxNonce,
    ...params,
  };

  return new Promise((resolve, reject) => {
    $.ajax({
      url: tacoVars.ajaxurl,
      method: 'POST',
      data: data,
      success: function (response) {
        resolve(response);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        reject(errorThrown);
      },
    });
  });
}

function validateBasket(params) {
  const data = {
    action: 'olo_validate_basket',
    nonce: tacoVars.ajaxNonce,
    ...params,
  };

  return new Promise((resolve, reject) => {
    $.ajax({
      url: tacoVars.ajaxurl,
      method: 'POST',
      data: data,
      success: function (response) {
        resolve(response);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        reject(errorThrown);
      },
    });
  });
}

function submitOrder(params) {
  const data = {
    action: 'olo_submit_order',
    nonce: tacoVars.ajaxNonce,
    ...params,
  };

  return new Promise((resolve, reject) => {
    $.ajax({
      url: tacoVars.ajaxurl,
      method: 'POST',
      data: data,
      success: function (response) {
        resolve(response);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        reject(errorThrown);
      },
    });
  });
}
