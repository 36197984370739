const openCloseTime = {
  open: { hours: 10, minutes: 0 },
  close: { hours: 21, minutes: 45 },
};

// Date validation
function validateDate(date) {
  let result = true;

  const dateformat =
    /^(0?[1-9]|1[0-2])[\/](0?[1-9]|[1-2][0-9]|3[01])[\/]\d{4}$/;

  // Matching the date through regular expression
  if (date.match(dateformat)) {
    const operator = date.split('/');

    // Extract the string into month, date and year
    let datepart = [];

    if (operator.length > 1) {
      datepart = date.split('/');
    }

    const month = parseInt(datepart[0]);
    const day = parseInt(datepart[1]);
    const year = parseInt(datepart[2]);

    // Create a list of days of a month
    const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    if (month == 1 || month > 2) {
      //to check if the date is out of range
      if (day > ListofDays[month - 1]) {
        result = false;
      }
    } else if (month == 2) {
      let isLeapYear = false;

      if ((!(year % 4) && year % 100) || !(year % 400)) {
        isLeapYear = true;
      }

      if (isLeapYear == false && day >= 29) {
        result = false;
      } else if (isLeapYear == true && day > 29) {
        result = false;
      }
    }
  } else {
    result = false;
  }

  return result;
}

// Function to validate the time in 12-hour format
function validateTime(str) {
  let result = true;

  // Regex to check valid time in 12-hour format
  let regex = new RegExp(/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/);

  // if str is empty return false
  if (str == null) {
    result = false;
  }

  // Return true if the str matched the ReGex
  if (regex.test(str) == false) {
    result = false;
  }

  return result;
}

// Validate location
function validateLocation(location) {
  let result = true;

  if (location.length <= 3) {
    result = false;
  }

  return result;
}

// Validate checkout inputs
function validateInput($input) {
  let res = true;
  const type = $input.attr('type');
  let val = $input.val();

  if (val.length == 0) {
    res = false;
  }

  switch (type) {
    case 'email':
      const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;

      if (!emailRegex.test(val)) {
        res = false;
      }

      break;

    case 'tel':
      const telRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

      if (!telRegex.test(val)) {
        res == false;
      }

      val = val.replace(/[^+\d]/g, '');

      if (val.length !== 10) {
        res = false;
      }

      break;

    default:
      break;
  }

  return res;
}

function getDateWithRoundTime(date) {
  const seconds = 900; // 15 minutes

  return Math.ceil(date / (seconds * 1000)) * (seconds * 1000);
}

function getOpeningDateAndTime(date) {
  return new Date(date).setHours(10, 0, 0);
}

function getNextDayDate(date) {
  return date + 60 * 60 * 24 * 1000;
}

function getLocaleTime(date) {
  return new Date(date).toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
}

function getCurrentPSTTime() {
  const now = new Date();

  // Convert to PST/PDT using the proper timezone name
  const pstDate = new Date(
    now.toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles',
    })
  );

  // Ensure we're creating a new Date object with the correct timezone offset
  const pstTime = new Date(
    pstDate.getFullYear(),
    pstDate.getMonth(),
    pstDate.getDate(),
    pstDate.getHours(),
    pstDate.getMinutes(),
    pstDate.getSeconds()
  );

  return pstTime;
}

function getStartDate() {
  const d = getCurrentPSTTime();
  const currentDate = d.getTime();
  let result = getDateWithRoundTime(getNextDayDate(currentDate));

  const tomorrowTime = {
    hours: new Date(result).getHours(),
    minutes: new Date(result).getMinutes(),
  };

  if (tomorrowTime.hours < openCloseTime.open.hours) {
    result = getOpeningDateAndTime(result);
  } else if (
    tomorrowTime.hours == openCloseTime.close.hours &&
    tomorrowTime.minutes >= openCloseTime.close.minutes
  ) {
    result = getNextDayDate(getOpeningDateAndTime(result));
  } else if (tomorrowTime.hours > openCloseTime.close.hours) {
    result = getNextDayDate(getOpeningDateAndTime(result));
  }

  return result;
}

function setTimePickerOptions($elem, startDate, wantedDate) {
  const interval = 900000; // 15 minutes as ms
  const wantedDateObj = new Date(wantedDate);
  const startDateObj = new Date(startDate);
  const wantedDateTimestamp = wantedDateObj.getTime();
  const startDateTimestamp = startDateObj.getTime();

  const start = wantedDateObj.setHours(
    openCloseTime.open.hours,
    openCloseTime.open.minutes
  );
  const end = wantedDateObj.setHours(
    openCloseTime.close.hours,
    openCloseTime.close.minutes
  );

  $elem.html('');

  for (let i = start; i <= end; i += interval) {
    const isDisabled = i < startDateTimestamp;
    const isSelected = i == wantedDateTimestamp;
    const attrDisabled = isDisabled ? 'disabled' : '';
    const attrSelected = isSelected ? 'selected' : '';
    const value = getLocaleTime(i);

    $elem.append(
      `<option ${attrDisabled} ${attrSelected} data-value="${value}">${value}</option>`
    );
  }
}

class ExpiringLocalStorage {
  static set(
    key,
    value,
    expiryDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
  ) {
    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: expiryDate.getTime(),
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  static get(key) {
    const itemStr = localStorage.getItem(key);
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }
}

function getGeocodeByAddress(address) {
  return new Promise((resolve, reject) => {
    try {
      let counter = 0;

      const getGeocoder = () => {
        counter++;

        const geocoder = new google.maps.Geocoder();

        geocoder.geocode(
          {
            address: address,
            componentRestrictions: { country: 'US' },
            region: 'us',
          },
          function (results, status) {
            if (status === 'OK' && results && results[0]) {
              resolve(results[0]);
            } else if (counter < 3) {
              debounce(getGeocoder(), 1000);
            } else {
              resolve(false);
            }
          }
        );
      };

      getGeocoder();
    } catch (error) {
      reject(error);
    }
  });
}

function getGeocodingByPlaceId(placeId) {
  return new Promise((resolve, reject) => {
    try {
      const geocoder = new google.maps.Geocoder();

      geocoder.geocode(
        {
          placeId: placeId,
        },

        function (results) {
          if (results && results[0].length !== 0) {
            resolve(results[0]);
          } else {
            resolve(false);
          }
        }
      );
    } catch (error) {
      reject(error);
    }
  });
}

function checkAddressInArea(addressCoords, areaCoords) {
  const area = new google.maps.Polygon({
    paths: areaCoords,
  });

  return area.containsLatLng(addressCoords?.lat, addressCoords?.lng);
}

function updateOrderTotals($input) {
  const $orderSubtotalCost = $('.subtotal-cost');
  const $orderTotalCost = $('.order-total-cost');
  const inputValue = Number($input.val());
  const inputCostPerUnit = Number($input.attr('data-cost-per-unit'));
  const inputTotalCost = Number($input.attr('data-total-cost'));
  const currentOrderSubtotalCost = Number(
    $orderSubtotalCost.text().replace(',', '')
  );
  const currentOrderTotalCost = Number($orderTotalCost.text().replace(',', ''));
  const newInputTotalCost = inputValue * inputCostPerUnit;
  const difference = newInputTotalCost - inputTotalCost;

  const isExtrasInput =
    $input.parents('.optiongroup-extras-field').length !== 0;

  let newExtrasTotal = 0;
  let newOrderSubtotal = currentOrderSubtotalCost;

  if (isExtrasInput) {
    const $extrasTotalCost = $('.extras-total-cost');
    const $extrasTotalRow = $('.extras-total-row');
    const currentExtrasTotal = Number($extrasTotalCost.text().replace(',', ''));

    newExtrasTotal = currentExtrasTotal + difference;

    // Show/hide extras row based on total
    if (newExtrasTotal > 0) {
      $extrasTotalRow.show();
    } else {
      $extrasTotalRow.hide();
    }

    $extrasTotalCost.text(
      newExtrasTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  } else {
    newOrderSubtotal += difference;
  }

  const newOrderTotal = currentOrderTotalCost + difference;

  $input.attr('data-total-cost', newInputTotalCost);

  $orderSubtotalCost.text(
    newOrderSubtotal.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  );

  $orderTotalCost.text(
    newOrderTotal.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  );
}

function getRestaurantIDFromUrl() {
  let restaurantID = '';

  const searchObject = {};
  const searchArray = window.location.search.replace('?', '').split('&');

  for (const element of searchArray) {
    const split = element.split('=');

    searchObject[split[0]] = split[1];
  }

  if (searchObject?.restaurant_id) {
    restaurantID = searchObject.restaurant_id;
  }

  return restaurantID;
}

// Remove all catering-related cookies
function clearCateringCookies({
  restaurantID = 0,
  isBasketDataDelete = false,
} = {}) {
  try {
    // Check if Cookies object exists
    if (typeof Cookies !== 'object' || typeof Cookies.get !== 'function') {
      console.error('Cookies API is unavailable. Cookie cleanup was not performed.');
      return;
    }

    // Get all cookies
    const allCookies = Cookies.get();
    
    // List of all possible paths where cookies could be set
    const possiblePaths = ['/', '/catering/', '/catering-order/'];
    
    // List of cookies for mandatory removal
    const criticalCookies = [
      'wordpress_catering_ordering_time',
      'wordpress_catering_modal_timeout',
      'wordpress_catering_wanted_date_and_time',
      'wordpress_catering_start_date_and_time'
    ];
    
    // Keywords for filtering cookies based on parameters
    const keywordsToDelete = [
      'date', 'time', 'type', 'pickup_address', 'delivery_address'
    ];
    
    // Remove critical cookies with all possible paths
    const removedCriticalCookies = [];
    criticalCookies.forEach(cookieName => {
      try {
        possiblePaths.forEach(path => {
          Cookies.remove(cookieName, { path: path });
        });
        // Also try to remove without specifying the path
        Cookies.remove(cookieName);
        removedCriticalCookies.push(cookieName);
      } catch (e) {
        // Fallback using document.cookie approach
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
        possiblePaths.forEach(path => {
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}`;
        });
      }
    });
    
    // List of removed cookies for reporting
    const removedCookies = [];
    
    // Process the remaining cookies based on conditions
    if (restaurantID && isBasketDataDelete) {
      Object.keys(allCookies).forEach((cookieName) => {
        const shouldDelete = 
          cookieName.includes('wordpress_catering') && 
          (cookieName.includes(`${restaurantID}`) || 
           keywordsToDelete.some(keyword => cookieName.includes(keyword)));
            
        if (shouldDelete) {
          try {
            possiblePaths.forEach(path => {
              Cookies.remove(cookieName, { path: path });
            });
            Cookies.remove(cookieName);
            removedCookies.push(cookieName);
          } catch (e) {
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            possiblePaths.forEach(path => {
              document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}`;
            });
          }
        }
      });
    } else {
      // Cookies to be preserved
      const excludedKeywords = ['basket_guid', 'billing_schemes_ids', 'order'];
      
      Object.keys(allCookies).forEach((cookieName) => {
        const shouldDelete = 
          cookieName.includes('wordpress_catering_') && 
          !excludedKeywords.some(keyword => cookieName.includes(keyword));
            
        if (shouldDelete) {
          try {
            possiblePaths.forEach(path => {
              Cookies.remove(cookieName, { path: path });
            });
            Cookies.remove(cookieName);
            removedCookies.push(cookieName);
          } catch (e) {
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            possiblePaths.forEach(path => {
              document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}`;
            });
          }
        }
      });
    }
    
    // Check cleanup results
    const remainingCookies = Cookies.get();
    const remainingCateringCookies = Object.keys(remainingCookies).filter(name => 
      name.includes('wordpress_catering_')
    );
    
    // Additional check for critical cookies
    const remainingCritical = criticalCookies.filter(name => remainingCookies[name]);
    if (remainingCritical.length > 0) {
      // Last attempt to remove critical cookies
      remainingCritical.forEach(cookieName => {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/catering/`;
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/catering-order/`;
      });
    }
  } catch (error) {
    console.error('[Cookies Cleanup] An error occurred during cookie cleanup:', error);
  }
}

// Remove all catering-related localStorage items
function clearCateringLocalStorage({
  restaurantID = 0,
  isBasketDataDelete = false,
} = {}) {
  // Get all localStorage keys
  const allKeys = Object.keys(localStorage);

  // Filter and remove items starting with specific prefixes
  if (restaurantID && isBasketDataDelete) {
    allKeys.forEach((key) => {
      if (
        key.includes(`wordpress_catering`) &&
        (key.includes(`${restaurantID}`) ||
          key.includes(`delivery_address`) ||
          key.includes(`nearby`))
      ) {
        localStorage.removeItem(key);
      }
    });
  } else {
    allKeys.forEach((key) => {
      if (key.includes('wordpress_catering_') && !key.includes(`basket`)) {
        localStorage.removeItem(key);
      }
    });
  }
}

/**
 * Create order URL with additional parameters
 */
function createOrderUrl(submitOrderResponse) {
  // Create a new URL with existing search parameters and additional order details
  const currentUrl = window.location.href;
  const currentUrlSearch = window.location.search;
  const url = new URL(currentUrlSearch, currentUrl);

  // Append new parameters
  url.searchParams.append('order_id', submitOrderResponse.data.id);

  return url.toString();
}

/**
 * Catering Loader
 * Shows/hides loader
 */
const toggleCateringLoader = (isLoading) => {
  const $ctLoader = $('.ct-loader');

  if (isLoading) {
    // Block interactions
    $ctLoader.addClass('is-visible');
  } else {
    // Remove loader
    $ctLoader.removeClass('is-visible');
  }
};

/**
 * Calculates a future date based on the current date and a specified number of days.
 *
 * @param {number} daysToAdd The number of days to add to the current date.
 * @returns {Date} A new Date object representing the future date.
 */
function getDateInFuture(daysToAdd) {
  const now = new Date();
  const futureDate = new Date(now);

  futureDate.setDate(now.getDate() + daysToAdd);

  return futureDate;
}

/**
 * Creates a debounced function that delays invoking the provided function until after the specified delay.
 * @param {Function} func - The function to debounce.
 * @param {number} delay - The delay in milliseconds.
 * @returns {Function} The debounced function.
 */
function debounce(func, delay) {
  let timeoutId;

  return function () {
    const context = this;
    const args = arguments;

    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
}

/**
 * Validate catering form fields and update UI
 */
async function validateCateringForm(formData, elements) {
  const { $dateField, $timeField, $locationField, $button } = elements;
  // Only validate if field matches form type
  const formType = $locationField.parents('form').attr('id'); // Get form type (pickup/delivery)

  let result = true;

  for (const [key, value] of formData) {
    switch (key) {
      case 'date':
        if (validateDate(value) === false) {
          $dateField.addClass('is-invalid');
          $button.removeClass('is-loading').attr('disabled', false);
          result = false;
        }
        break;

      case 'time':
        if (validateTime(value) === false) {
          $timeField.addClass('is-invalid');
          $button.removeClass('is-loading').attr('disabled', false);
          result = false;
        }
        break;

      case 'pickup_address':
        if (key.includes(formType)) {
          if (validateLocation(value) === false) {
            $locationField.addClass('is-invalid');
            $button.removeClass('is-loading').attr('disabled', false);
            result = false;
          } else if (!window.ctGeocodeResponse?.[formType]) {
            console.log('Geocode response not found');

            window.ctGeocodeResponse[formType] = await getGeocodeByAddress(
              value
            );

            if (!window.ctGeocodeResponse[formType]) {
              $locationField.addClass('is-invalid');
              $button.removeClass('is-loading').attr('disabled', false);
              result = false;
            }
          }
        }
        break;
      case 'delivery_address':
        if (key.includes(formType)) {
          if (validateLocation(value) === false) {
            $locationField.addClass('is-invalid');
            $button.removeClass('is-loading').attr('disabled', false);
            result = false;
          } else if (!window.ctGeocodeResponse?.[formType]) {
            console.log('Geocode response not found');

            window.ctGeocodeResponse[formType] = await getGeocodeByAddress(
              value
            );

            if (!window.ctGeocodeResponse[formType]) {
              $locationField.addClass('is-invalid');
              $button.removeClass('is-loading').attr('disabled', false);
              result = false;
            } else {
              const addressComponents =
                window.ctGeocodeResponse[formType].address_components;
              const requiredComponents = [
                'postal_code',
                'locality',
                'street_number',
                'route',
              ];
              const hasAllComponents = requiredComponents.every((component) =>
                addressComponents.some((addressComponent) =>
                  addressComponent.types.includes(component)
                )
              );

              if (!hasAllComponents) {
                $locationField.addClass('is-invalid');
                $button.removeClass('is-loading').attr('disabled', false);
                result = false;
              }
            }
          }
        }
        break;
    }
  }

  return result;
}

/**
 * Extract coordinates from geocode response
 */
function extractAddressCoords(geocodeResponse, type) {
  if (!geocodeResponse?.[type]) return null;

  const coordsArray = geocodeResponse[type]?.geometry?.location
    .toString()
    .replace(/(\(|\))+/g, '')
    .split(', ');

  return {
    lat: parseFloat(coordsArray[0]),
    lng: parseFloat(coordsArray[1]),
  };
}

/**
 * Save catering form data to cookies
 */
function saveCateringFormData(data, options) {
  const { type, date, time, address, wantedDateAndTimeMs } = data;
  const cookiesOptions = options;

  Cookies.set('wordpress_catering_type', type, cookiesOptions);
  Cookies.set('wordpress_catering_date', date, cookiesOptions);
  Cookies.set('wordpress_catering_time', time, cookiesOptions);
  Cookies.set(`wordpress_catering_${type}_address`, address, cookiesOptions);
  Cookies.set(
    'wordpress_catering_wanted_date_and_time',
    wantedDateAndTimeMs,
    cookiesOptions
  );
}

/**
 * Reset geocode response data
 */
function resetGeocodeResponse(type, geocodeResponse) {
  if (geocodeResponse?.[type]) {
    geocodeResponse[type] = null;
  }

  if (window.ctGeocodeResponse?.[type]) {
    window.ctGeocodeResponse[type] = null;
  }
}

/**
 * Save cookies to new cookie before clearing them
 */
function saveOrderCookies(orderId, restaurantID) {
  const type = Cookies.get('wordpress_catering_type');
  const type_address = Cookies.get(`wordpress_catering_${type}_address`);
  const name = Cookies.get(`wordpress_catering_${restaurantID}_name`);
  const address = Cookies.get(`wordpress_catering_${restaurantID}_address`);
  const date = Cookies.get('wordpress_catering_wanted_date_and_time');
  const time = Cookies.get('wordpress_catering_time');

  const cookiesData = {
    type,
    type_address,
    date,
    time,
    name,
    address,
  };

  // Save data in a new cookie that expires in 1 hour
  Cookies.set(
    `wordpress_catering_order_${orderId}`,
    JSON.stringify(cookiesData),
    {
      expires: new Date(new Date().getTime() + 60 * 60 * 1000), // 1 hour
    }
  );
}

/**
 * Format date and time in PST timezone
 * @param {number} timestamp - Unix timestamp in milliseconds
 * @param {string} format - 'date' for date only, 'time' for time only, 'full' for both
 * @returns {string} Formatted date/time string in PST
 */
function formatPSTDateTime(timestamp, format = 'full') {
  const options = {
    timeZone: 'America/Los_Angeles',
  };

  // Create a date object in PST timezone
  const pstDate = new Date(
    new Date(timestamp).toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles',
    })
  );

  // Return date object if format is 'object'
  if (format === 'object') {
    return pstDate;
  }

  switch (format) {
    case 'date':
      options.weekday = 'long';
      options.year = 'numeric';
      options.month = 'long';
      options.day = 'numeric';
      break;
    case 'time':
      options.hour = 'numeric';
      options.minute = 'numeric';
      options.hour12 = true;
      break;
    default:
      options.weekday = 'long';
      options.year = 'numeric';
      options.month = 'long';
      options.day = 'numeric';
      options.hour = 'numeric';
      options.minute = 'numeric';
      options.hour12 = true;
  }

  return new Date(timestamp).toLocaleString('en-US', options);
}
