function closeInfoModal(type = null) {
  console.log('closeInfoModal', type);

  const $infoModal = $('.info-modal').filter('.active');
  let $modalContent = $infoModal.find('.modal-content');

  if (type) {
    $modalContent = $modalContent.filter(`[data-type="${type}"]`);

    if (type == 'timeout') {
      Cookies.remove('wordpress_catering_modal_timeout');

      $modalContent.removeClass('active');
      $infoModal.removeClass('active');

      window.location.reload();
    } else if (type == 'error') {
      $modalContent.find('.custom-title').text('');
      $modalContent.find('.custom-message').text('');

      $modalContent.removeClass('active');
      $infoModal.removeClass('active');

      window.location.reload();
    } else if (type == 'pay-error') {
      $modalContent.removeClass('active');
      $infoModal.removeClass('active');
    }
  }
}

function openInfoModal(type, options = { title: null, message: null }) {
  const $modal = $('.info-modal');
  const $modalContent = $modal.find(`.modal-content[data-type="${type}"]`);

  if (!$modalContent.length) return;

  if (type === 'error') {
    const $modalTitle = $modalContent.find('.modal-title');
    const $modalText = $modalContent.find('.modal-text');

    if (options.title || options.message) {
      $modalTitle.text(options.title);
      $modalText.text(options.message);
    }
  }

  $modal.addClass('active');
  $modalContent.addClass('active');
}

jQuery(function ($) {
  const $orderPage = $('.page-template-page-catering-order');

  // If the page is not found, exit early
  if ($orderPage.length == 0) return;

  const handleOverlayClick = function (event) {
    // Close modal if clicked directly on the overlay
    if ($(event.target).hasClass('info-modal')) {
      closeInfoModal();
    }
  };

  const $infoModal = $('.info-modal');

  $infoModal.find('.modal-btn').on('click', function () {
    closeInfoModal($(this).parents('.modal-content').data('type'));
  });

  // Add click event handler for overlay to close modal
  $infoModal.on('click', handleOverlayClick);

  // Check for timeout modal cookie
  if (Cookies.get('wordpress_catering_modal_timeout') === 'show') {
    openInfoModal('timeout');
  }
});
