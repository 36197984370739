jQuery(function ($) {
  const $orderPage = $('.page-template-page-catering-order');
  const $firstStep = $('.first-step');

  // If the page or first step element is not found, exit early
  if ($orderPage.length == 0 || $firstStep.length == 0) return;

  const minLength = 3;

  // Options shared between delivery and pickup
  const baseOptions = {
    componentRestrictions: { country: 'us' },
    fields: ['address_components', 'geometry'],
    locationRestriction: {
      north: 49.0,
      south: 45.5,
      west: -124.6,
      east: -116.9,
    },
    strictBounds: true,
    language: 'en',
  };

  const deliveryOptions = {
    ...baseOptions,
    fields: [...baseOptions.fields, 'formatted_address'],
    types: ['address'],
  };

  const pickupOptions = {
    ...baseOptions,
    types: ['(regions)'],
    // types: ['establishment'],
  };

  const formatDeliveryAddress = function (addressComponents) {
    const address = {};

    addressComponents.forEach((component) => {
      const type = component.types[0];

      address[type] =
        type == 'administrative_area_level_1' || type == 'route'
          ? component.short_name
          : component.long_name;
    });

    let formattedAddress = '';

    formattedAddress += `${address?.street_number || ''} ` || '';
    formattedAddress += address?.route ? `${address?.route}, ` : '';
    formattedAddress += address?.locality ? `${address?.locality}, ` : '';
    formattedAddress += `${address?.administrative_area_level_1 || ''} ` || '';
    formattedAddress += `${address?.postal_code || ''}` || '';
    formattedAddress = formattedAddress.trim();

    return formattedAddress;
  };

  const handlePlace = function ($input, place, isDelivery) {
    if (!place.address_components) return;

    const formId = isDelivery ? '#delivery' : '#pickup';

    if (isDelivery) {
      const formattedAddress = formatDeliveryAddress(place.address_components);
      $input.val(formattedAddress);
      $(formId).trigger('submit');
    } else {
      const address = {};
      place.address_components.forEach((component) => {
        const type = component.types[0];
        address[type] =
          type == 'administrative_area_level_1'
            ? component.short_name
            : component.long_name;
      });

      const formattedAddress =
        address.postal_code && address.locality
          ? `${address.locality}, ${address.administrative_area_level_1} ${address.postal_code}`
          : `${address.locality}, ${address.administrative_area_level_1}`;

      $input.val(formattedAddress);
      $(formId).trigger('submit');
    }
  };

  const toggleOptionsList = function ($input) {
    const value = $input.val().trim();
    const $optionsList = $input.parents('.ct-field').next('.options-list');

    if (value.length >= minLength) {
      if ($optionsList.children().length > 0) {
        $optionsList.addClass('is-active');
      } else {
        $optionsList.removeClass('is-active');
      }
    } else {
      $optionsList.removeClass('is-active');
    }
  };

  const optionItemClickHandler = async function (e) {
    const $this = $(this);
    const placeId = $this.attr('data-place-id');
    const $thisInput = $this
      .parents('.options-list')
      .siblings('.ct-field')
      .find('input'); // Reference to the input from outer scope
    const isDelivery = $thisInput.attr('id').includes('delivery');

    let placeDetails;
    try {
      placeDetails = await getGeocodingByPlaceId(placeId);
    } catch (error) {
      console.log(error);
    }

    if (!placeDetails) {
      return;
    }

    const orderType = isDelivery ? 'delivery' : 'pickup';

    if (!window.ctGeocodeResponse) {
      window.ctGeocodeResponse = {};
    }

    window.ctGeocodeResponse[orderType] = placeDetails;
    handlePlace($thisInput, placeDetails, isDelivery);
  };

  // Input event handlers
  const inputHandler = function ($input) {
    let placesService;

    const $deliveryOptionsList = $('#delivery-options-list');
    const $pickupOptionsList = $('#pickup-options-list');

    let $optionsList = null;

    // Remove old handler before adding new one
    $(document).off('click', '.option-item');

    // Add handler once outside the input event
    $(document).on('click', '.option-item', optionItemClickHandler);

    $input.on(
      'input',
      debounce((e) => {
        const $thisInput = $(e.target);
        const isDelivery = $thisInput.attr('id').includes('delivery');

        if (isDelivery) {
          $optionsList = $deliveryOptionsList;
        } else {
          $optionsList = $pickupOptionsList;
        }

        if (!placesService) {
          placesService = new google.maps.places.AutocompleteService();
        }

        const value = $thisInput.val().trim();
        let washingtonPredictions = [];

        $optionsList.empty();

        placesService.getPlacePredictions(
          {
            input: value,
            ...(isDelivery ? deliveryOptions : pickupOptions),
          },
          (predictions, status) => {
            if (
              status === google.maps.places.PlacesServiceStatus.OK &&
              predictions
            ) {
              washingtonPredictions = predictions.filter(
                (prediction) =>
                  prediction.description.includes('WA') ||
                  prediction.description.includes('Washington')
              );

              if (isDelivery) {
                washingtonPredictions = washingtonPredictions.filter(
                  (prediction) => prediction.types.includes('street_address')
                );
              }

              if (washingtonPredictions.length > 0) {
                washingtonPredictions.forEach((prediction) => {
                  const matchesOffsets = prediction.matched_substrings;
                  let description = prediction.description.replace(', USA', '');
                  let descriptionChunks = [];
                  descriptionChunks = description.split('');

                  matchesOffsets.forEach((match) => {
                    const start = match.offset;
                    const end = match.offset + match.length;

                    descriptionChunks[
                      start
                    ] = `<span class="option-matched">${descriptionChunks[start]}`;
                    descriptionChunks[end - 1] = `${
                      descriptionChunks[end - 1]
                    }</span>`;
                  });

                  description = descriptionChunks.join('');

                  $optionsList.append(
                    `<div class="option-item" data-place-id="${prediction.place_id}">
                        <span class="option-icon option-icon-marker"></span>
                        <span class="option-item-query">
                          <span>${description}</span>
                        </span>
                      </div>`
                  );
                });
              }
            }
            toggleOptionsList($thisInput);
          }
        );

        $input.on('blur', () => toggleOptionsList($thisInput));
        $input.on('focus', () => toggleOptionsList($thisInput));
      }, 200)
    );
  };

  const handleOptionsListOutsideClick = function (event) {
    if (
      !$(event.target).hasClass('options-list') &&
      !$(event.target).hasClass('ct-field-input')
    ) {
      $('.options-list').removeClass('is-active');
    }
  };

  $(window).on('click', handleOptionsListOutsideClick);

  // Update input selector to handle both types of fields
  const $pickupAddressInput = $('input[name="pickup_address"]');
  const $deliveryAddressInput = $('input[name="delivery_address"]');

  // Initialize handlers for both inputs
  inputHandler($pickupAddressInput);
  inputHandler($deliveryAddressInput);
});
